@charset "utf-8";

@use "styles/variables";

.component.ReceivedChildMailListItem {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;
    background-color: #FFFFFF;

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .row-1 {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .receiver {
                font-weight: bold;
            }
            .date {
                font-size: 0.8rem;
                color: variables.$color-gray-date;
            }
        }
        .row-2 {
            text-align: start;
        }
        .row-3 {
            font-weight: bold;
            font-size: 1.2rem;
            word-break: break-all;
            text-align: start;
        }
        .divider {
            width: 100%;
            margin-top: 0;
            margin-bottom: 5px;
            opacity: 1.0;
            background-color: #000000;
        }
        .row-4 {
            text-align: start;
            word-break: break-all;
        }
        .button-area {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 10px 0 5px 0;
            .btn-delete {
                color: #FFFFFF;
                background-color: variables.$bg-color-button-delete;
                &:hover {
                    background-color: variables.$bg-color-button-delete-hover;
                }
            }
            .btn-read {
                color: #FFFFFF;
                background-color: variables.$bg-color-read;
                &:hover {
                    background-color: variables.$bg-color-read-hover;
                }
            }
        }
    }
}
.component.ReceivedChildMailListItem.unread {
    background-color: variables.$bg-color-unread3;
}
.component.ReceivedChildMailListItem.sent-mail {
    background-color: #EEEEEE;
}
@media screen and (min-width:600px) {
    .component.ReceivedChildMailListItem {
        padding: 10px;
        .wrapper {
            .divider {
                margin-top: 0;
                margin-bottom: 10px;
            }
            .button-area {
                margin: 20px 0 10px 0;
            }
        }
    }
}