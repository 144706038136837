@charset "utf-8";

@use "styles/variables";

.NavBar {
    position: relative;
    display: block;
    width: 100%;
    height: variables.$navbar-height;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .dummy-for-sp {
        width: 100%;
        height: variables.$navbar-height;
    }
    .navbar-area {
        position: fixed;
        top: variables.$header-height;
        left: 0;
        width: 100%;
        height: variables.$navbar-height;
        display: block;
        z-index: 10;

        .navbar-dummy-area {
            display: none;
        }
        .navbar-contents {
            background-color: variables.$bg-color-navbar;
            color: #ffffff;
            overflow: hidden;
            h1.navbar-title {
                width: 100%;
                height: variables.$navbar-height;
                line-height: variables.$navbar-height;
                margin: 0;
                color: #ffffff;
                font-weight: bold;
                font-size: 1.1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                padding: 0 10px;
            }
            h1.navbar-title.show-back {
                padding-left: 80px;
                padding-right: 80px;
            }
            h1.navbar-title.show-right-button {
                padding-left: 80px;
                padding-right: 80px;
            }
            .back-button-area {
                position: absolute;
                height: 100%;
                width: 80px;
                left: 10px;
                top: 0;
                bottom: 0;
                flex-grow: 0;
                flex-shrink: 0;
                flex-direction: row;
                align-items: center;
                color: #ffffff;
                font-size: 1.0rem;
                font-weight: bold;
                cursor: pointer;

                img.img-back {
                    object-fit: contain;
                    flex-grow: 0;
                    flex-shrink: 0;
                    height: 50%;
                    margin-right: 10px;

                }
            }
            .call-button-area {
                position: absolute;
                height: 100%;
                width: auto;
                right: 5px;
                top: 0;
                bottom: 0;
                display: flex;
                flex-grow: 0;
                flex-shrink: 0;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                img {
                    object-fit: contain;
                    height: 80%;
                    background-color: green;
                    padding: 5px;
                    border-radius: 9999px;
                }
            }
        }
    }
}
@media screen and (min-width:600px) {
    .NavBar.hidden {
        display: none;
    }
}
@media screen and (min-width:1536px) {
    .NavBar {
        .navbar-area {
            display: flex;
            flex-direction: row;
            .navbar-dummy-area {
                display: block;
                width: variables.$ad-area-width;
                height: 100%;
                flex-grow: 0;
                flex-shrink: 0;
            }
            .navbar-contents {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
    }
}