@charset "utf-8";

@use "styles/variables";

.pageWrapper.Register {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;
    background: variables.$bg-color-app;
    padding-bottom: variables.$page-padding-bottom-sp;


    form {
        position: relative;
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 30px;

        div.google-oauth-area {
            flex-grow: 0;
            flex-shrink: 0;
            width: auto;
        }
        div.google-oauth-area.loaded {
            display: block;
            padding-bottom: 10px;
        }
    
        .entry-item-area {
            display: flex;
            flex-direction: colomn;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: 5px;
            .item-label-wrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                column-gap: 10px;
                label {
                    text-align: left;
                }
                img {
                    height: 20px;
                    object-fit: contain;
                }
            }
            .entry-field {
                flex-grow: 1;
                flex-shrink: 1;
                height: variables.$text-field-height;
                background-color: #ffffff;
                padding: 0;
                border-radius: 5px;
                .MuiOutlinedInput-input {
                    height: 100%;
                    padding: 0 10px;
                    background-color: #ffffff;
                    border-radius: 5px;
                }
            }
            .entry-select {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                height: variables.$text-field-height;
                margin-top: 0 !important;
                text-align: left;
                padding-left: 15px;
            }
            .entry-tag-wrapper {
                width: 100%;
                min-height: 100px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                background-color: #ffffff;
                padding: 5px;
                .tag-item {
                    height: 20px;
                    line-height: 20px;
                    color: #ffffff;
                    margin: 2px;
                    padding: 0 8px;
                    border-radius: 9999px;
                    font-size: 1.0rem;
                }
                .tag-item.male {
                    background-color: variables.$color-gender-male;
                }
                .tag-item.female {
                    background-color: variables.$color-gender-female;
                }
                .tag-item.other {
                    background-color: variables.$color-gender-other;
                }
            }
            .entry-profile-picture-wrapper {
                width: 100%;
                height: 150px;
                position: relative;
                background-color: #ffffff;
                color: #aaaaaa;
                .picture-area-wrapper {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        padding: 10px;
                    }
                    img {
                        position: absolute;
                        left: 0;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .precautions {
                font-size: 0.8rem;
                color: #cacaca;
                margin-bottom: 0;
            }
            .entry-profile-wrapper {
                width: 100%;
                min-height: 150px;
                text-align: left;
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                padding: 5px;
            }
        }
        .button-area {
            position: relative;
            width: 100%;
            padding-top: 30px;
            text-align: center;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
                cursor: pointer;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled !important;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.Register {
        padding-bottom: variables.$page-padding-bottom-pc;
        overflow: visible;
        h1.page-title {
            font-size: variables.$fontsize-page-title-pc;
            color: #ffffff;
            text-align: center;
            padding-top: 20px;
        }
        form {
            width: 70%;
            max-width: 800px;
            padding-top: 10px;
            .entry-item-area {
                padding-bottom: 10px;
                .entry-tag-wrapper {
                    padding: 10px;
                    .tag-item {
                        height: 30px;
                        line-height: 30px;
                        font-size: 1.3rem;
                    }
                }    
                .precautions {
                    font-size: 0.9rem;
                }
            }
            .button-area {
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
    }
}