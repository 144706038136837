@charset "utf-8";

@use "styles/variables";

.ResponseListItem {
    position: relative;
    width: 100%;
    margin: 5px auto;
    font-size: 1rem;
    padding: 5px;
    overflow: initial !important;
    .card-header {
        display: flex;
        flex-direction: row;
        padding: 0;
        .header-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            .index {
                font-size: 1.2rem;
            }
            .img-flag {
                object-fit: contain;
                height: 20px;
                margin-right: 5px;
            }
            .user-name {
                font-size: 1.2rem;
            }
            .age-area {
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                flex-direction: row;
                font-size: 0.9rem;
            }
            .attribute-area {
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                margin-left: 5px;
                .attribute {
                    color: #ffffff;
                    border-radius: 9999px;
                    font-size: 0.8rem;
                    padding: 1px 5px;
                }
                .attribute.male {
                    background-color: variables.$color-gender-male;
                }
                .attribute.female {
                    background-color: variables.$color-gender-female;
                }
                .attribute.other {
                    background-color: variables.$color-gender-other;
                }
            }
            .posted-date {
                margin-left: 10px;
                font-size: 0.8rem;
                color: #777777;
            }
        }
    }
    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 5px;
        text-align: left;
        .body-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}
@media screen and (min-width:600px) {
    .ResponseListItem {
        margin: 10px auto;
        padding: 10px;
    }
}