@charset "utf-8";

@use "styles/variables";

div.ad-area.bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: variables.$footer-height;
    width: 100%;
    height: variables.$ad-bottom-height;
    background-color: #000000;
    overflow: hidden;
    color: #000000;

    a {
        color: #0000ff;
    }

    .slider-container {
        ul {
            list-style: none;
            padding: 0;
            div.ad-item {
                width: 100%;
                height: variables.$ad-bottom-height;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
@media screen and (min-width:600px) {
    div.ad-area.bottom {
        display: none;
    }
}
