@charset "utf-8";

@use "styles/variables";

.pageWrapper.AlbumList {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .no-album-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 90%;
        margin: auto;
        padding-top: 30px;

        .contents-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;

            .guide-sentenses {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                font-size: 1.2rem;
                font-weight: bold;
                margin-top: 30px;
                padding-bottom: 125px;
            }
        }
        .btn-area {
            position: fixed;
            bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
            left: 0;
            width: 100%;
            text-align: center;
        }
    }

    .album-list-area-wrapper {
        padding-bottom: 100px;
        .album-list-area {
            display: flex;
            flex-direction: column;
            .album-list-header {
                h1 {
                    font-weight: bold;
                    font-size: 1.1rem;
                    text-align: center;
                    color: white;
                    margin: 0;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    word-break: break-all;
                }
            }
            .album-list-header.public {
                background-image: url("../../../../public/images/section_header/section_header_album_public.png");
                background-repeat: repeat-x;
                background-size: contain;
            }
            .album-list-header.follower {
                background-image: url("../../../../public/images/section_header/section_header_album_follower.png");
                background-repeat: repeat-x;
                background-size: contain;
            }
            .album-list-header.group {
                background-image: url("../../../../public/images/section_header/section_header_album_group.png");
                background-repeat: repeat-x;
                background-size: contain;
            }
            .album-list-header.user {
                background-image: url("../../../../public/images/section_header/section_header_album_user.png");
                background-repeat: repeat-x;
                background-size: contain;
            }
            .album-list-header.private {
                background-image: url("../../../../public/images/section_header/section_header_album_private.png");
                background-repeat: repeat-x;
                background-size: contain;
            }
            ul.album-list-contents-wrapper {
                position: relative;
                list-style: none;
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                column-gap: 5px;
                row-gap: 5px;
                li.album-wrapper {
                    position: relative;
                    width: 20vw;
                    max-width: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    touch-action: none;
                    cursor: grab;
            
                    img.img-album-template {
                        object-fit: contain;
                        width: 20vw;
                        max-width: 100px;
                    }
                    span.album-name {
                        text-align: center
                    }
                }
                li.album-wrapper.selected {
                    background-color: variables.$bg-color-selected;
                }
                li.album-wrapper.dragging {
                    z-index: 100;
                }
            }
        }
        .operation-area {
            position: fixed;
            left: 0;
            right: 0;
            bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 10px);
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 20px;

            .operation-item {
                background-color: #ffffff;
                border-radius: 50%;
                height: 40px;
                cursor: pointer;
                box-shadow: 1px 1px 5px 0px #333333;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.AlbumList {
        .no-album-page {
            width: 80%;
            .contents-wrapper {
                min-width: 530px;
                .guide-sentenses {
                    width: 80%;
                    margin: auto;
                    margin-top: 40px;
                }
            }
            .btn-area {
                bottom: variables.$bottom-button-padding-bottom-pc;
            }
        }

        .album-list-area-wrapper {
            .album-list-area {
                ul.album-list-contents-wrapper {
                    padding: 10px 50px;
                    column-gap: 10px;
                    row-gap: 10px;
                }
            }
            .operation-area {
                bottom: 10px;

                .operation-item {
                    height: 70px;
                    box-shadow: 5px 5px 10px 0px #333333;
                }
            }
        }
    }
}