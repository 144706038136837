@charset "utf-8";

@use "styles/variables";

.component.ReceivedChildMailComponent {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
    background: #CCCCCC;
    color: #000000;
    overflow: auto;

    a {
        color: #0000ff;
    }
    .list-wrapper {
        padding: 10px;
        padding-bottom: calc(10px + variables.$ad-bottom-height);
    }
    .icon-reply-wrapper {
        display: flex;
        position: fixed;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        right: 10px;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px 0px #333333;
        z-index: 50;
        cursor: pointer;
        img {
            display: inline-block;
            object-fit: cover;
            color: #0000ff;
            height: 30px;
        }
    }
}
@media screen and (min-width:600px) {
    .component.ReceivedChildMailComponent {
        .list-wrapper {
            padding: 0;
            padding-bottom: 0;
        }
        .icon-reply-wrapper {
            display: none;
        }
        .icon-reply-wrapper.disabled {
            cursor: not-allowed;
        }
    }
}