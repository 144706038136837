@charset "utf-8";

@use "styles/variables";

body {
    .pageWrapper.Board {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        background: variables.$bg-color-app;
        overflow: auto;
        >.contents-wrapper {
            position: relative;
            width: 100%;
            height: calc(100% - variables.$navbar-height);
            display: flex;
            flex-direction: row;
            .category-list-wrapper {
                width: 100%;
            }
            .thread-list-wrapper {
                flex-grow: 1;
                flex-shrink: 1;
            }
            .thread-post-wrapper {
                flex-grow: 1;
                flex-shrink: 1;
            }
            .response-list-wrapper {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
    }
}
@media screen and (min-width:600px) {
    body {
        .pageWrapper.Board {
            display: block;
            overflow: visible;
            >.contents-wrapper {
                height: 100%;
                .category-list-wrapper {
                    width: auto;
                    min-width: 250px;
                }
            }
        }
    }
}