@charset "utf-8";

@use "styles/variables";

.component.ThreadListComponent {
    position: relative;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    .dummy-for-sp {
        display: none;
    }
    .no-thread-page {
        position: relative;
        width: 100%;
        height: 100%;
        .no-thread-title {
            text-align: center;
            margin-top: 30px;
            color: #ffffff;
            font-size: variables.$fontsize-page-title-sp;
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
            }
        }
    }
    .contents-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding: 10px 2% 50px 2%;
        .thread-title {
            width: 100%;
            max-width: 100%;
            display: inline;
            margin-right: 0;
            margin-bottom: 5px;
            font-size: 1.1rem;
            // color: variables.$a-color;
            color: #ffffff;
            text-decoration: underline;
            word-break: break-all;
        }
        .thread-title:hover {
            cursor: pointer;
        }
    }
    .icon-write {
        position: fixed;
        width: 50px;
        height: 50px;
        right: 10px;
        top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px 0px #333333;
        z-index: 50;
        img {
            object-fit: cover;
            color: #0000ff;
            height: 30px;
        }
    }
    .icon-write:hover {
        cursor: pointer;
    }
}
@media screen and (min-width:600px) {
    .component.ThreadListComponent {
        .no-thread-page {
            margin-top: 30px;
            font-size: variables.$fontsize-page-title-pc;
            .button-area {
                position: absolute;
                bottom: variables.$bottom-button-padding-bottom-pc;
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
        .contents-wrapper {
            display: block;
            padding: 30px 20px 50px 20px;
            .thread-title {
                width: auto;
                margin-right: 20px;
            }
        }
        .icon-write {
            width: 80px;
            height: 80px;
            right: 20px;
            box-shadow: 5px 5px 10px 0px #333333;
            img {
                height: 50px;
            }
        }
    }
}
@media screen and (min-width:1536px) {
    .component.ThreadListComponent {
        .icon-write {
            right: calc(20px + variables.$ad-area-width);
        }
    }
}