@charset "utf-8";

@use "styles/variables";

.pageWrapper.NotFoundPage {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 15%;
    color: #ff0000;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    background: variables.$bg-color-app;
}
@media screen and (min-width:600px) {
    .pageWrapper.NotFoundPage {
        padding-top: 5%;
    }
}