@charset "utf-8";

@use "styles/variables";

.pageWrapper.MyPage {
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    background: variables.$bg-color-app;
    overflow: auto;
    .contents-wrapper {
        position: relative;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 90%;
        margin: auto;

        .divider {
            background-color: #000000;
            margin-top: 2px;
        }
        .btn-cancel {
            margin-right: 10px;
        }
        .text-input-area {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            height: 45px;
            line-height: 45px;
            margin-top: 5px;
        }
        .area-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            font-weight: bolder;
            color: #ffffff;

            .label-title {
                flex-grow: 1;
                flex-shrink: 1;
            }
            .button-area {
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                flex-direction: row;
                .app-button {
                    width: auto;
                    padding: 3px 6px;
                    font-size: 0.9rem;
                }
            }
        }
        .value-label {
            display: block;
            text-align: left;
        }
        .select-box {
            flex-grow: 1;
            flex-shrink: 1;
            background-color: #ffffff;
            height: 45px;
            margin-top: 0 !important;
            text-align: left;
            display: flex;
            align-items: center;
        }
        .profile-link {
            width: 100%;
            font-size: variables.$fontsize-page-title-sp;
            font-weight: bolder;
            text-align: center;
            color: #ffffff;
            text-decoration-color: #ffffff;
            padding-top: variables.$page-title-padding-top;
        }
        .username-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .profile-picture-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .value-label {
                img {
                    object-fit: cover;
                    height: 150px;
                }
            }
            .file-select-area {
                position: relative;
                background-color: #ffffff;
                width: 100%;
                height: auto;
                color: #aaaaaa;
                display: block;
                .dropzone {
                    width: 100%;
                    height: 150px;
                    .image-area-wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                        .profile-picture-guide {
                            padding: 10px;
                        }
                        img {
                            position: absolute;
                            left: 0;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
            .precautions {
                font-size: 0.8rem;
                color: #cacaca;
                margin-bottom: 0;
            }
        }
        .profile-text-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .value-wrapper {
                text-align: left;
                min-height: 150px;
                color: #000000;
            }
        }
        .gender-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .age-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .region-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .attribute-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .interest-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .value-wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .tag-item {
                    color: #ffffff;
                    margin: 2px;
                    padding: 2px 8px;
                    border-radius: 9999px;
                }
                .tag-item.male {
                    background-color: variables.$color-gender-male;
                }
                .tag-item.female {
                    background-color: variables.$color-gender-female;
                }
                .tag-item.other {
                    background-color: variables.$color-gender-other;
                }
            }
        }
        .gallery-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .follow-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .follower-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .block-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .group-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .notification-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
        }
        .country-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .value-wrapper {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                justify-content: flex-start;
                align-items: center;
                img {
                    object-fit: contain;
                    width: 30px;
                }
            }
        }
        .account-area {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            .button-area {
                button {
                    padding: 2px 8px;
                }
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.MyPage {
        overflow: visible;
        .contents-wrapper {
            .area {
                min-height: 120px;
            }     
            .area-wrapper {
                .button-area {
                    .app-button {
                        font-size: 1.0rem;
                        padding: 6px 12px;
                    }
                    &.account {
                        width: auto;
                    }
                }
            }
            .profile-link {
                font-size: variables.$fontsize-page-title-pc;
            }
            .username-area {
                
            }
            .profile-picture-area {
                .precautions {
                    font-size: 0.9rem;
                }
            }
            .profile-text-area {
                
            }
            .gender-area {
                
            }
            .age-area {
                
            }
            .region-area {
                
            }
            .attribute-area {
                .interest-area {
                    .value-wrapper {
                        .tag-item {
                            margin: 5px;
                            padding: 6px 24px;
                        }
                        .tag-item.male {
                            
                        }
                        .tag-item.female {
                            
                        }
                        .tag-item.other {
                            
                        }
                    }
                }
            }
            .interest-area {
                
            }
            .gallery-area {
                
            }
            .group-area {
            }
            .account-area {
                .button-area {
                    button {
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}