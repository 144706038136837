@charset "utf-8";

@use "styles/variables";

.pageWrapper.GalleryList {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    .no-gallery-page {
        height: 100%;
        width: 85%;
        margin: auto;
        padding-top: 50px;
        color: variables.$color-primary;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
    }

    .album-precautions {
        width: 90%;
        margin: auto;
        margin-top: 20px;
        text-align: center;
        color: #ffffff;
        font-size: 0.9rem;
    }
    // .gallery-contents {
    //     position: relative;
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: wrap;
    //     padding: 10px 10px 30px 10px;
    //     column-gap: 10px;
    //     row-gap: 10px;
    //     justify-content: center;

    //     .dummy {
    //         width: 40vw;
    //         height: 40vw;
    //     }
    // }
    .gallery-contents {
        position: relative;
        display: grid;
        justify-content: center;
        align-items: flex-start;
        grid-template-columns: repeat(auto-fill, variables.$gallery-content-width-sp); 
        grid-auto-rows: variables.$gallery-content-width-sp;
        column-gap: 10px;
        row-gap: 10px;
        padding: 10px 10px 70px 10px;
        list-style: none;
        .gallery-item.pc {
            touch-action: none;
        }
    }
    .dropzone {
        position: relative;
        width: 100%;
        height: calc(100% - variables.$navbar-height);
    }
    .operation-area {
        position: fixed;
        left: 0;
        right: 0;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 10px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 20px;

        .operation-item {
            background-color: #ffffff;
            border-radius: 50%;
            height: 40px;
            cursor: pointer;
            box-shadow: 1px 1px 5px 0px #333333;
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GalleryList {
        overflow: visible;
        .no-gallery-page {
            width: 80%;
        }
        .album-precautions {
            font-size: 1.0rem;
        }
        .gallery-contents {
            grid-template-columns: repeat(auto-fill, variables.$gallery-content-width-pc); 
            grid-auto-rows: variables.$gallery-content-width-pc;
            row-gap: 20px;
            column-gap: 20px;
            margin-top: 20px;
            padding: 10px;
        }
        .dropzone {
        }
        .operation-area {
            bottom: 10px;

            .operation-item {
                height: 70px;
                box-shadow: 5px 5px 10px 0px #333333;
            }
        }
    }
}