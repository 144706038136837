@charset "utf-8";

@use "styles/variables";

.component.ShootComponent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - variables.$navbar-height);
    margin: auto;
    overflow: auto;
}
@media screen and (min-width:600px) {
    .component.ShootComponent {
        width: 95%;
    }
}