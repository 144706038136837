@charset "utf-8";

@use "styles/variables";

.component.SendMessageComponent {
    position: relative;
    height: 100%;
    form.entry-area {
        position: relative;
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 30px;
        padding-bottom: 150px;

        .entry-wrapper {
            .item-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 0;
                row-gap: 3px;

                label {
                    width: 100%;
                    text-align: left;
                    font-size: variables.$fontsize-label-title;
                    line-height: 1;
                }
                .input-wrapper {
                    position: relative;
                    width: 100%;
                    min-height: variables.$text-field-height;
                    background-color: #ffffff;
                    border-radius: 4px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid rgba(0, 0, 0, 0.23);

                    .destination-items-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        column-gap: 5px;
                        row-gap: 5px;
                        padding: 5px;

                        .destination-item {
                            background-color: #000000;
                            color: #ffffff;
                            border-radius: 5px;
                            line-height: 1;
                            padding: 3px 5px;
                        }
                        .destination-item.male {
                            background-color: variables.$color-gender-male;
                        }
                        .destination-item.female {
                            background-color: variables.$color-gender-female;
                        }
                    }
                }
                .input-title {
                    flex-grow: 1;
                    flex-shrink: 1;
                    background-color: #ffffff;
                    height: variables.$text-field-height;
                    font-size: 1rem;
                    .MuiOutlinedInput-input {
                        height: 100%;
                        padding: 0 10px;
                        background-color: #ffffff;
                        border-radius: 5px;
                    }
                }
            }
            .item-area.title-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 20px;
            }
            .item-area.body-area {
                margin-top: 20px;
                .label-area-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .icon-attached-file {
                        transform: rotate(45deg);
                        cursor: pointer;
                        color: #000000;
                    }
                }
                .input-body {
                    flex-grow: 1;
                    flex-shrink: 1;
                    background-color: #ffffff;
                    font-size: 1rem;
                    border-radius: 4px;
                    .MuiInputBase-root {
                        padding: 5px;
                    }
                }
            }
            .attached-file-area {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 2px;
                column-gap: 5px;
                margin-top: 5px;
            }
        }
        .button {
            position: fixed;
            width: variables.$bottom-button-width-sp;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            right: 0;
            margin: auto;
            font-size: variables.$fontsize-button;
            cursor: pointer;
        }
        .button.disabled {
            color: #aaaaaa;
            cursor: not-allowed;
        }
    }
    .entry-area.error {
        padding-top: 20px;
    }
}
@media screen and (min-width:600px) {
    .component.SendMessageComponent {
        max-height: 80vh;
        overflow: visible;
        height: auto;
        form.entry-area {
            padding-top: 30px;
            padding-bottom: variables.$bottom-button-distance-dialog;
            .entry-wrapper {
                .item-area {
                    .input-wrapper {
                        .destination-items-wrapper {
                            width: 100%;
                            .destination-item {
                                border-radius: 5px;
                                padding: 5px 10px;
                            }
                        }
                    }
                }
                .title-area {
                    margin-top: 30px;
                }
                .body-area {
                    margin-top: 30px;
                }
                .attached-file-area {
                    padding-bottom: 0;
                }
            }
            .button {
                position: static;
                width: 50%;
                margin-top: 80px;
            }
        }
        .entry-area.error {
            padding-top: 20px;
        }    
    }
}