@charset "utf-8";

@use "styles/variables";

body {
    .pageWrapper.SentParentMail {
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        background: variables.$bg-color-app;
        display: flex;
        flex-direction: column;
        color: #000000;

        a {
            color: #0000ff !important;
        }
        .no-sent-page {
            color: #0000ff;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
            margin-top: 30px;
        }
        .contents-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            .parent-list-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                overflow: auto;
                border-right: none;
                background: variables.$bg-color-app;
                .item-wrapper {
                    position: relative;
                    display: block;
                }
            }
            .parent-list-wrapper.no-record {
                width: 100%;
                min-width: unset;
                max-width: unset;
            }
            .child-list-wrapper {
                width: 100%;
                height: 100%;
                display: none;
            }
            .icon-new-wrapper {
                position: fixed;
                width: 50px;
                height: 50px;
                right: 10px;
                top: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 9999px;
                background-color: #ffffff;
                box-shadow: 1px 1px 5px 0px #333333;
                z-index: 50;
                cursor: pointer;
                img {
                    display: inline-block;
                    object-fit: cover;
                    color: #0000ff;
                    height: 30px;
                }
            }
        }
    }
    .scroll-trigger {
        position: fixed;
        bottom: 100px;
        right: 10px;
        z-index: 100;
    }
}
@media screen and (min-width:600px) {
    body {
        .pageWrapper.SentParentMail {
            .no-sent-page {
                margin-top: 50px;
            }
            .contents-wrapper {
                height: 100%;
                .parent-list-wrapper {
                    position: relative;
                    width: 25vw;
                    min-width: 400px;
                    max-width: 500px;
                    overflow: auto;
                    border-right: 1px solid #000000;
                }
                .child-list-wrapper {
                    display: block;
                }
                .icon-new-wrapper {
                    right: 20px;
                    top: 115px;
                    box-shadow: 5px 5px 10px 0px #333333;
                }
            }
        }
        .scroll-trigger {
            bottom: 32px;
            right: 20px;
        }    
    }
}
@media screen and (min-width:600px) {
    body {
        .scroll-trigger {
            left: 320px;
        }    
    }
}
@media screen and (min-width:1536px) {
    body {
        .pageWrapper.SentParentMail {
            .contents-wrapper {
                .icon-new-wrapper {
                    right: calc(20px + variables.$ad-area-width);
                }
                .icon-reply-wrapper {
                    right: calc(90px + variables.$ad-area-width);
                }
            }
        }
        .scroll-trigger {
            left: calc(320px + variables.$ad-area-width);
        }    
    }
}

