@charset "utf-8";

@use "styles/variables";

.pageWrapper.Mail {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background: variables.$bg-color-app;
    padding-bottom: 0;
    box-sizing: border-box;
    .tab-area {
        position: fixed;
        top: variables.$header-height;
        width: 100%;
        height: variables.$tabbar-height-sp;
        z-index: 10;
        .tab-wrapper {
            width: 100%;
            height: variables.$tabbar-height-sp;
            min-height: 0px;
            display: flex;
            flex-direction: row;
            .tab {
                height: 100%;
                min-height: variables.$tabbar-height-sp;
                flex-grow: 1;
                flex-shrink: 1;
                max-width: none;
                padding: 0;
                font-size: 1.0rem;
                background-color: variables.$bg-color-tab;
                color: #ffffff;
            }
            .tab.selected {
                background-color: variables.$bg-color-selected-tab;
            }
        }
    }
    .tab-panel {
        width: 100%;
        height: 100%;
        padding-top: variables.$tabbar-height-sp;
        box-sizing: border-box;
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.Mail {
        .tab-area {
            height: variables.$tabbar-height-pc;
            .tab-wrapper {
                height: variables.$tabbar-height-pc;
                .tab {
                    min-height: variables.$tabbar-height-pc;
                }
            }
        }
        .tab-panel {
            height: calc(100% - variables.$tabbar-height-pc);
            padding-top: variables.$tabbar-height-pc;
            box-sizing: content-box;
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.Mail {
        .tab-area {
            width: calc(100% - variables.$ad-area-width * 2);
        }
    }
}