@charset "utf-8";

@use "styles/variables";

.UserListItem2 {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    .card-image {
        width: 100%;
        height: 80px;
        object-fit: cover;
    }
    .card-content {
        position: relative;
        padding: 5px;
        text-align: left;
        .info-wrapper-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            .flag-image {
                object-fit: contain;
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .attribute {
                color: #ffffff;
                border-radius: 9999px;
                font-size: 0.7rem;
                padding: 1px 6px;
            }
            .attribute.male {
                background-color: variables.$color-gender-male;
            }
            .attribute.female {
                background-color: variables.$color-gender-female;
            }
            .attribute.other {
                background-color: variables.$color-gender-other;
            }
        }
        .info-wrapper-2 {
            font-weight: bold;
            font-size: 1.0rem;
            word-break: break-all;
        }
        .info-wrapper-3 {
            display: flex;
            flex-direction: row;
            .age {
                font-size: 0.8rem;
            }
            .gender {
                font-size: 0.8rem;
            }
        }
        .info-wrapper-4 {
            font-size: 0.8rem;
        }
        .info-wrapper-5 {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.7rem;
            color: #aaaaaa;
            .card-icon {
                font-size: 1.0rem;
            }
        }
        .button-area {
            width: 100%;
            text-align: center;
            .btn-unblock {
                width: 100%;
                padding: 0;
                background-color: variables.$color-danger;
                color: #ffffff;
            }
        }
    }
    .user-label {
        position: absolute;
        top: 0;
        left: 0;
        .online {
            background-color: variables.$color-online;
            color: #ffffff;
            border-radius: 9999px;
            padding: 2px 6px;
            margin: 2px;
            font-size: 0.7rem;
        }
        .follow {
            background-color: variables.$color-follow;
            color: #ffffff;
            border-radius: 9999px;
            padding: 2px 6px;
            margin: 2px;
            font-size: 0.7rem;
        }
    }
    .selected-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: variables.$bg-color-selected-layer;
        z-index: 10;
    }
}
@media screen and (min-width:600px) {
    .UserListItem2 {
        .card-image {
            height: 150px;
        }    
        .card-content {
            padding: 10px;
            .info-wrapper-1 {
                .flag-image {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
                .attribute {
                    padding: 2px 15px;
                }
            }
            .button-area {
                .btn-unblock {
                    margin-top: 10px;
                    padding: 6px 8px;
                }
            }
        }
        .online {
            padding: 4px 12px;
            margin: 4px;
        }
        .follow {
            padding: 4px 12px;
            margin: 4px;
        }
    }
}