@charset "utf-8";

@use "styles/variables";

.AttachedFileNameComponent {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #ffffff;
    background-color: variables.$color-selected-file;
    .filename {
        padding: 3px 10px;
        text-align: left;
        word-break: break-all;
        cursor: default;
    }
    .delete-icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
    }
}
.AttachedFileNameComponent.hidden {
    display: none;
}
@media screen and (min-width:600px) {
    .AttachedFileNameComponent {
    }
}