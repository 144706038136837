@charset "utf-8";

@use "styles/variables";

.pageWrapper.UserDetail {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: variables.$page-padding-bottom-sp;
    .no-user-page {
        color: #ff0000;
        font-size: 1.2rem;
        margin-top: 30px;
    }
    .title-header {
        color: #ffffff;
        font-size: 1.2rem;
        text-align: center;
        padding: 2px 0;
        margin: 0;
        background: linear-gradient(#eee, #aaa);
    }
    .title-header.male {
        background: linear-gradient(#05fbff, #1e00ff);
    }
    .title-header.female {
        background: linear-gradient(#ffaad5, #ff2b95);
    }
    .basic-info-area {
        .contents-wrapper {
            display: flex;
            flex-direction: column;
            .left-pane {
                position: relative;
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                position: relative;
                img {
                    width: 100%;
                    object-fit: cover;
                    background-color: #ffffff;
                }
                .btn-block {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: rgba(200, 0, 0, 0.8);
                    color: #ffffff;
                    font-size: 0.8rem;
                }
                .btn-unblock {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: rgba(0, 200, 0, 0.8);
                    color: #ffffff;
                    font-size: 0.8rem;
                }
                .profile-button-area {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    column-gap: 10px;
                    position: relative;
                    margin: auto;
                    margin-top: 10px;
                    padding: 0 10px;
                    .btn-follow {
                        width: 48%;
                        background-image: url(../../../../public/images/buttons/bg_button_follow.png);
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        border-radius: 5px;
                        color: #ffffff;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .btn-follow.under-follow {
                        background-image: url(../../../../public/images/buttons/bg_button_unfollow.png);
                    }
                    .btn-invite {
                        width: 48%;
                        background-image: url(../../../../public/images/buttons/bg_button_invite.png);
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        border-radius: 5px;
                        color: #ffffff;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            .right-pane {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                margin: 0;
                padding: 10px;

                .user-info-item-label {
                    width: 27vw;
                    min-width: 130px;
                    max-width: 180px;
                    background-color: #aa88ff;
                    color: #ffffff;
                    text-align: left;
                    padding: 5px 0 5px 10px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                .user-info-item-value {
                    margin-left: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .user-info-item-value.country {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .username-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                }
                .country-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                    img {
                        object-fit: contain;
                        height: 20px;
                    }
                }
                .region-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                }
                .age-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                }
                .gender-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                }
                .attribute-area {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;
                }
                .last-access-area {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
    .interest-area {
        .contents-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 10px;
            .tag {
                margin: 2px;
                color: #ffffff;
                padding: 2px 8px;
                border-radius: 9999px;
                font-size: 1.3rem;
            }
            .tag.male {
                background-color: variables.$color-gender-male;
            }
            .tag.female {
                background-color: variables.$color-gender-female;
            }
            .tag.other {
                background-color: variables.$color-gender-other;
            }
        }
    }
    .message-area {
        .contents-wrapper {
            padding: 10px;
            color: #000000;
        }
    }
    .gallery-area {
        .contents-wrapper {
            .sub-header {
                color: #ffffff;
                text-align: center;
                font-size: 1.1rem;
                padding: 0 10px;
                background-color: #aeaeae;
                word-break: break-all;
            }
            .sub-header.public {
                background-color: #55a957;
            }
            .sub-header.follower {
                background-color: #a99a55;
            }
            .sub-header.group {
                background-color: #920a93;
            }
            .sub-header.user {
                background-color: #5591a9;
            }
            .gallery-contents-wrapper {
                position: relative;
                display: grid;
                justify-content: center;
                align-items: flex-start;
                grid-template-columns: repeat(auto-fill, variables.$gallery-content-width-sp); 
                grid-auto-rows: variables.$gallery-content-width-sp;
                column-gap: 10px;
                row-gap: 10px;
                padding: 10px 10px;
                .gallery-item-wrapper {
                    position: relative;
                }
            }
        }
    }
    .dummy-area {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }
    .button-area {
        position: relative;
        display: flex;
        width: 50%;
        min-width: 250px;
        height: 130px;
        min-height: 130px;
        margin: auto;
        margin-top: 30px;
        .image-area {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            object-fit: contain;
            width: 130px;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .btn-text {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                word-wrap: break-word;
            }
        }
        .image-area.message {
            left: 0;
            cursor: pointer;
        }
        .image-area.chat {
            right: 0;
        }
        .image-area.tweet {
            width: 100px;
            right: 0;
        }
    }
    .button-area.exist-tweet {
        width: 90%;
        img.exist-tweet {
            width: 100px;
        }
        img.chat.exist-tweet {
            left: 0;
            right: 0;
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.UserDetail {
        overflow: visible;
        padding-bottom: 0;
        .no-user-page {
            margin-top: 50px;
        }
        .basic-info-area {
            .contents-wrapper {
                margin: auto;
                flex-direction: row;
                padding: 30px;
                .left-pane {
                    img {
                        width: 40vw;
                        max-width: 300px;
                        height: 40vw;
                        max-height: 300px;
                    }    
                    .profile-button-area {
                        width: 100%;
                        .btn-follow {
                            width: 48%;
                        }
                        .btn-invite {
                            width: 48%;
                        }
                    }
                }
                .right-pane {
                    padding: 0;
                    padding-left: 1vw;
                    .user-info-item-value {
                        margin-left: 10px;
                        font-size: 1.2rem;
                    }
                    .username-area {
                        margin-bottom: 10px;
                    }
                    .country-area {
                        margin-bottom: 10px;
                        img {
                            height: 40px;
                        }
                    }
                    .region-area {
                        margin-bottom: 10px;
                    }
                    .age-area {
                        margin-bottom: 10px;
                    }
                    .gender-area {
                        margin-bottom: 10px;
                    }
                    .attribute-area {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .interest-area {
            .contents-wrapper {
                margin: auto;
                padding: 30px;
                .tag {
                    margin: 5px;
                    padding: 6px 24px;
                }
            }
        }
        .message-area {
            .contents-wrapper {
                margin: auto;
                padding: 30px;
            }
        }
        .gallery-area {
            .contents-wrapper {
                .sub-header {
                    padding: 0 30px;
                }
                .gallery-contents-wrapper {
                    grid-template-columns: repeat(auto-fill, variables.$gallery-content-width-pc); 
                    grid-auto-rows: variables.$gallery-content-width-pc;
                    row-gap: 20px;
                    column-gap: 20px;
                }
            }
        }
        .button-area {
            width: 400px;
            height: 200px;
            min-height: 200px;
            margin-top: 50px;
            bottom: variables.$bottom-button-padding-bottom-pc;
            .image-area {
                width: 150px;
                &:hover {
                    cursor: pointer;
                    width: 180px;
                    font-size: 1.3rem;
                }
            }
            .image-area.message {
                left: 15px;
                &:hover {
                    // width: 200px;
                    left: 0;
                }
            }
            .image-area.chat {
                right: 15px;
                &:hover {
                    // width: 200px;
                    right: 0;
                }
            }
            .image-area.chat.exist-tweet {
                left: 0;
                right: 0;
                &:hover {
                    // width: 200px;
                    left: 0;
                    right: 0;
                }
            }
            .image-area.tweet {
                width: 150px;
                right: 15px;
                &:hover {
                    // width: 200px;
                    right: 0;
                }
            }
        }
        .button-area.exist-tweet {
            width: 800px;
            img.exist-tweet {
                width: 150px;
                &:hover {
                    width: 180px;
                }
            }    
        }
    }
}
