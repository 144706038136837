// $bg-color-app: linear-gradient(180deg, #ff55ff 0%, rgb(0, 153, 255) 100%);
$bg-color-app: linear-gradient(180deg, rgba(255, 85, 255, 0.9) 0%, rgba(0, 153, 255, 0.9) 100%);
// $bg-color-app: #EEEEFF;
$bg-color-navbar: #990099;
$bg-color-selected: #cc90ff;
$bg-color-selected-layer: rgba(204, 144, 255, 0.6);
$bg-color-button-disabled: #AAAAAA;
$bg-color-group-owner: #3F51B5;
$bg-color-group-applying: #AAAA33;
$bg-color-group-under-invited: #AAAA33;
$bg-color-group-member: #55AA55;
$bg-color-group-private: #999999;
$bg-color-tab: #AA88FF;
$bg-color-selected-tab: #AA00FF;
$bg-color-attached-file: #cccccc;
$bg-color-read: #AAAA00;
$bg-color-read-hover: #888800;
$bg-color-unread: #AAAA00;
$bg-color-unread2: #CCCCCC;
$bg-color-unread3: #FFFFCC;
$bg-color-button-delete: #FF5555;
$bg-color-button-delete-hover: #AA2222;


$color-gender-male: #55AAFF;
$color-gender-female: #FF55CC;
$color-gender-other: #999999;
$color-owner: #3F51B5;
$color-online: #00cc00;
$color-follow: #cccc00;
$color-primary: #3F51B5;
$color-danger: #dc3545;
$color-purple: #aa55ff;
$color-dark-yellow: #aaaa55;
$color-selected-file: #CC55FF;
$color-gray-date: #777777;
$a-color: #ffff00;

$page-title-padding-top: 20px;

$bottom-button-padding-bottom-sp: 30px;
$bottom-button-padding-bottom-pc: 50px;
$bottom-button-distance: 85px;
$bottom-button-distance-dialog: 30px;

$bottom-button-width-sp: 70%;
$bottom-button-width-pc: 350px;
$bottom-2button-width: 40vw;
$bottom-2button-max-width-sp: 150px;
$bottom-2button-max-width-pc: 200px;
$ad-area-width: 320px;
$attached-file-width-sp1: 100%;
$attached-file-width-pc: 250px;
$gallery-content-width-sp: 45vw;
$gallery-content-width-pc: 250px;
$user-list-item-width-sp: 110px;
$user-list-item-width-pc: 200px;

$header-height: 40px;
$navbar-height: 40px;
$footer-height: 65px;
$text-field-height: 45px;
$tabbar-height-sp: 40px;
$tabbar-height-pc: 40px;
$ad-bottom-height: 50px;
$attached-file-height-sp: auto;
$attached-file-height-pc: 250px;
$user-list-item-height-sp: 135px;
$user-list-item-height-pc: 215px;

$fontsize-page-title-sp: 1.2rem;
$fontsize-page-title-pc: 1.5rem;
$fontsize-mypage-title-pc: 1.3rem;
$fontsize-button: 1.0rem;
$fontsize-label-title: 1.0rem;

$page-padding-bottom-sp: 70px;
$page-padding-bottom-pc: 50px;

$attached-file-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);