@charset "utf-8";

@use "styles/variables";

.pageWrapper.Top {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    color: #000000;
    background-color: #ffffff;

    .start-free {
        position: fixed;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 10px);
        right: 10px;
        padding: 10px 20px;
        background-color: rgba(102, 170, 255, 0.5);
        color: #ffffff;
        z-index: 10;
        border-radius: 9999px;
        text-decoration: none;
        
        &:hover {
            transform: scale(1.1);
            background-color: #66aaff;
        }
    }
    section.hero {
        position: relative;
        width: 100%;
        // height: 100%;
        height: calc(100vh - variables.$header-height - variables.$footer-height - variables.$ad-bottom-height);

        .image-area {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
            img.hero1 {
                animation: heroImage1 30s infinite;
            }
            img.hero2 {
                animation: heroImage2 30s infinite;
            }
            img.hero3 {
                animation: heroImage3 30s infinite;
            }
        }
        .catch-phrase {
            position: relative;
            font-size: 1.3rem;
            padding-top: 40px;
            padding-left: 5%;
            color: #ffffff;
            .catch-phrase-2 {
                position: relative;
                padding-left: 10%;
                font-size: 1.7rem;
            }
        }
    }
    section.concept-area {
        padding-top: 20px;
        position: relative;
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: "Shippori Mincho";
        font-size: 1.3rem;
    }
    section.feature-area {
        padding-top: 20px;
        position: relative;
        width: 100%;
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }
        div.section-contents {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;
            article {
                position: relative;
                width: 90%;
                background: linear-gradient(180deg, #f4dcc4 0%, #c6b6a7 100%);
                border-radius: 10px;
                padding: 10px;
                box-shadow: 2px 2px 5px 0px #333333;
                h2 {
                    font-size: 1.2rem;
                    font-weight: bold;
                    text-align: center;
                }
                p {
                    word-wrap: break-word;
                }
            }
        }
    }
    section.howto-area {
        padding-top: 20px;
        margin-top: 40px;
        padding-bottom: 30px;
        position: relative;
        width: 100%;
        color: #ffffff;
        background-color: rgba(255, 85, 255, 0.5);
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }
        div.section-contents {
            position: relative;
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            article.howto {
                position: relative;
                display: flex;
                flex-direction: column;
                column-gap: 10px;
                h2 {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    .number {
                        flex-grow: 0;
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #585858;
                        border: 3px solid #ffffff;
                        border-radius: 50%;
                        font-size: 1.3rem;
                    }
                    .howto-title {
                        flex-grow: 1;
                        flex-shrink: 1;
                        font-size: 1.15rem;
                        font-weight: bold;
                        color: #5555cc;
                    }
                }
                .howto-detail {
                    padding-left: 50px;
                }
            }
        }
    }
    section.security-area {
        padding-top: 20px;
        padding-bottom: 30px;
        position: relative;
        width: 100%;
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }
        ul.section-contents-list {
            position: relative;
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: column;
            list-style: none;
            row-gap: 20px;
            li {
                font-size: 1.15rem;
            }
        }
    }
    section.engagement-area {
        padding-top: 20px;
        padding-bottom: calc(variables.$ad-bottom-height + 80px);
        position: relative;
        width: 100%;
        color: #ffffff;
        background-color: rgba(0, 153, 255, 0.5);
        h1 {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
        }
        ul.section-contents-list {
            position: relative;
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: column;
            list-style: none;
            row-gap: 20px;
            li {
                font-size: 1.15rem;
            }
        }
    }
    @keyframes heroImage {
        0%, 100% {
            background-image: url('../../assets/images/bg_top_1.webp');
        }
        33.333% {
            background-image: url('../../assets/images/bg_top_2.webp');
        }
        66.666% {
            background-image: url('../../assets/images/bg_top_3.webp');
        }
    }
    @keyframes heroImage1 {
        from {
            opacity: 1.0;
        }
        30% {
            opacity: 1.0;
        }
        33%, 97% {
            opacity: 0;
        }
        to {
            opacity: 1.0;
        }
        
    }
    @keyframes heroImage2 {
        from {
            opacity: 0;
        }
        30% {
            opacity: 0;
        }
        33%, 63% {
            opacity: 1.0;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes heroImage3 {
        from {
            opacity: 0;
        }
        63% {
            opacity: 0;
        }
        66%, 97% {
            opacity: 1.0;
        }
        to {
            opacity: 0;
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.Top {
        .start-free {
            bottom: 30px;
            right: 40px;
            padding: 20px 40px;
            font-size: 1.5rem;
        }
        section.hero {
            width: 100%;
            height: calc(100vh - variables.$header-height);
            .catch-phrase {
                padding-top: 80px;
                padding-left: 5%;
                font-size: 2.0rem;
                .catch-phrase-2 {
                    font-size: 3.0rem;
                }
            }
        }
        section.concept-area {
            font-size: 1.6rem;
        }
        section.feature-area {
            padding-top: 50px;
            padding-bottom: 50px;
            h1 {
                font-size: 2.0rem;
            }
            div.section-contents {
                padding-top: 20px;
                column-gap: 40px;
                row-gap: 30px;
                article {
                    width: 25%;
                    min-width: 250px;
                    max-width: 400px;
                    min-height: 250px;
                    box-shadow: 5px 5px 10px 0px #333333;
                    padding: 15px 20px;
                    h2 {
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-align: center;
                    }
                    p {
                        word-wrap: break-word;
                        font-size: 1.2rem;
                        color: #353535;
                    }
                }
            }
        }
        section.howto-area {
            padding-top: 50px;
            padding-bottom: 50px;
            h1 {
                font-size: 2.0rem;
            }
            div.section-contents {
                row-gap: 40px;
                article.howto {
                    h2 {
                        .number {
                            width: 50px;
                            height: 50px;
                            font-size: 1.5rem;
                        }
                        .howto-title {
                            font-size: 1.5rem;
                        }
                    }
                    .howto-detail {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        section.security-area {
            padding-top: 50px;
            padding-bottom: 50px;
            h1 {
                font-size: 2.0rem;
            }
            ul.section-contents-list {
                padding-top: 20px;
                li {
                    font-size: 1.2rem;
                }
            }
        }
        section.engagement-area {
            padding-top: 50px;
            padding-bottom: 150px;
            color: #ffffff;
            h1 {
                font-size: 2.0rem;
            }
            ul.section-contents-list {
                padding-top: 20px;
                li {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.Top {
        .start-free {
            bottom: 30px;
            right: calc(variables.$ad-area-width + 40px);
            padding: 20px 40px;
            font-size: 1.5rem;
        }
    }
}