@charset "utf-8";

@use "styles/variables";

.sns-popover {
    .sns-link {
        display: flex;
        flex-direction: row;
    }
    img {
        height: 30px;
        object-fit: contain;
        &.twitter {
            height: 25px;
        }
    }
}
.AppBarArea {
    position: relative;
    width: 100%;
    height: variables.$header-height;
    z-index: 100;
    .appBarAreaDummy {
        width: 100%;
        height: variables.$header-height;
    }
    .app-bar {
        background-color: #000000;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        height: variables.$header-height;
        overflow: hidden;
        .app-bar-container {
            height: 100%;
            .tool-bar {
                position: relative;
                display: block;
                height: 100%;
                min-height: initial;
                .link {
                    height: 100%;
                    .app-icon-area.sp {
                        position: relative;
                        display: flex;
                        height: 100%;
                        flex-grow: 1;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        img#app_icon {
                            height: 30px;
                            object-fit: cover;
                        }
                    }
                    .app-icon-area.pc {
                        display: none;
                    }
                }
                .app-menu-wrapper.pc {
                    display: none;
                }
                .select-language {
                    font-size: 0.8rem;
                    color: #ffffff;
                }
                .app-login-register.pc {
                    display: none;
                }
                .profile-image-area.pc {
                    display: none;
                    .profile-sub-menu.pc {
                        display: none;
                    }
                }
                .burger-menu-icon-area {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 0;
                    top: 0;
                    right: 0;
                    height: 100%;
                    .menu-icon {
                        position: relative;
                        width: 25px;
                        height: 100%;
                        span {
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            display: inline-block;
                            background-color: white;
                            -webkit-transition: 0.35s ease-in-out;
                            -moz-transition: 0.35s ease-in-out;
                            transition: 0.35s ease-in-out;
                        }
                        span.close:nth-of-type(1) {
                            top: 30%;
                        }
                        span.close:nth-of-type(2) {
                            opacity: 1.0;
                            top: 50%;
                        }
                        span.close:nth-of-type(3) {
                            top: 70%;
                        }
                        span.open:nth-of-type(1) {
                            top: 50%;
                            transform: rotate(45deg);
                        }
                        span.open:nth-of-type(2) {
                            width: 0;
                            left: 50%;
                        }
                        span.open:nth-of-type(3) {
                            top: 50%;
                            transform: rotate(-45deg);
                        }
                    }
                }
                @keyframes showOverlay {
                    0% {
                        left: 0;
                        opacity: 0.0;
                    }
                    100% {
                        left: 0;
                        opacity: 0.5;
                    }
                }
                @keyframes hideOverlay {
                    0% {
                        left: 0;
                        opacity: 0.5;
                    }
                    99% {
                        left: 0;
                        opacity: 0.0;
                    }
                    100% {
                        left: 100%;
                    }
                }
                .overlay {
                    position: fixed;
                    top: variables.$header-height;
                    bottom: calc(variables.$footer-height + variables.$ad-bottom-height);
                    width: 100%;
                    background-color: rgb(0, 0, 0);
                    display: block;
                }
                .overlay.show {
                    animation: showOverlay 0.35s both;
                }
                .overlay.hidden {
                    animation: hideOverlay 0.35s both;
                }
                .burger-menu-sheet {
                    position: fixed;
                    width: 200px;
                    top: variables.$header-height;
                    right: -200px;
                    transition: 0.35s ease-in-out;
                    background-color: #ffffff;
                    color: #000000;

                    &.open {
                        right: 0;
                    }
                    .menu-list {
                        padding-top: 0;
                        padding-bottom: 0;
                        overflow: auto;
                        .burger-menu-item {
                            border-bottom: 1px solid #000000;

                            &:last-of-type {
                                border-bottom: none;
                            }
                            &.sub {
                                border-bottom: 1px solid #000000;
                                padding-bottom: 5px;

                                .lang-name {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    &.active {
                                        color: #ff0000;
                                        font-weight: bold;
                                    }
                                }
                                .sns-link {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    img {
                                        height: 30px;
                                        object-fit: contain;
                                        &.twitter {
                                            height: 25px;
                                        }
                                    }
                                }
                            }
                        }
                        .burger-menu-item.open {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}
.burger-menu {
    .burger-menu-item {
        display: block;
    }
    .burger-menu-item.hidden {
        display: none;
    }
}
@media screen and (min-width:600px) {
    .AppBarArea {
        .app-bar {
            .app-bar-container {
                .tool-bar {
                    display: flex;
                    .link {
                        .app-icon-area.pc {
                            display: flex;
                            position: relative;
                            height: 100%;
                            align-items: center;
                            margin-right: 8px;
                            img#app_icon {
                                height: 80%;
                                object-fit: cover;
                            }
                        }
                        .app-icon-area.sp {
                            display: none;
                        }
                    }
                    .app-menu-wrapper.pc {
                        position: relative;
                        height: 100%;
                        flex-grow: 1;
                        display: block;
                        overflow-x: auto;
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        .app-menu {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            display: flex;
                            .menu-item {
                                position: relative;
                                display: block;
                                .button {
                                    color: #ffffff;
                                    width: 100%;
                                    height: 100%;
                                    min-width: max-content;
                                }
                                .badge {
                                    position: absolute;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 2px;
                                    min-width: 15px;
                                    min-height: 15px;
                                    top: 0;
                                    right: 0;
                                    font-size: 0.7rem;
                                    background-color: #ff0000;
                                    color: #ffffff;
                                    border-radius: 9999px;
                                }
                                .badge.none {
                                    display: none;
                                }
                            }
                        }
                    }
                    .app-login-register.pc {
                        display: flex;
                        flex-direction: row;
                        font-size: 0.8rem;
                        .register,
                        .login {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 15px;
                            color: #00ffff;
                        }
                    }
                    .user-settings-area {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .profile-image-area.pc.login {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .username {
                                margin-right: 10px;
                                font-size: 1.0rem;
                            }
                            .profile-image {
                                height: 35px;
                                width: 35px;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                            .profile-sub-menu.pc {
                                display: block;
                                .sub-menu-item {
                                    display: block;
                                }
                            }
                        }
                    }
                    .burger-menu-icon-area {
                        display: none;
                    }
                    .overlay {
                        display: none;
                    }
                    .burger-menu-sheet {
                        display: none;
                    }
                }
            }
        }    
    }
    .burger-menu {
        display: none;
    }
}