@charset "utf-8";

@use "styles/variables";

.component.ThreadPostComponent {
    position: relative;
    height: 100%;
    margin: auto;
    background: variables.$bg-color-app;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    .dummy-for-sp {
        display: none;
    }
    .error-message {
        display: flex;
    }
    h1.page-title {
        font-size: variables.$fontsize-page-title-pc;
        color: variables.$color-primary;
        text-align: center;
        padding-top: 20px;
        color: #ffffff;
    }
    form {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;
        margin: auto;
        .title-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 0;
            .title-label {
                width: 100%;
                text-align: left;
            }
            .title-value {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                height: variables.$text-field-height;
                padding: 0;
                border-radius: 4px;
                .MuiOutlinedInput-input {
                    height: 100%;
                    padding: 0 10px;
                    background-color: #ffffff;
                    border-radius: 5px;
                }
            }
        }
        .body-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            .body-label {
                width: 100%;
                text-align: left;
            }
            .body-value {
                position: relative;
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                border-radius: 5px;
                .MuiOutlinedInput-root {
                    background-color: #ffffff;
                    textarea {
                    }
                }
            }
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            text-align: center;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled;
                color: #000000;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .component.ThreadPostComponent {
        .dummy-for-sp {
            display: none;
        }
        h1.page-title {
            font-size: variables.$fontsize-page-title-pc;
            color: variables.$color-primary;
            text-align: center;
            padding-top: variables.$page-title-padding-top;
            color: #ffffff;
        }
        form {
            width: 70%;
            max-width: 800px;
            padding-top: 10px;
            .body-area {
                padding-bottom: 200px;
            }    
            .button-area {
                position: absolute;
                bottom: variables.$bottom-button-padding-bottom-pc;
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
    }
}