@charset "utf-8";

@use "styles/variables";

.mainWrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: variables.$bg-color-app;
    box-sizing: border-box;

    div.main {
        position: relative;
        height: calc(100% - variables.$header-height - variables.$footer-height);
        width: 100%;
        margin: 0 auto;
        flex-grow: 1;
        flex-shrink: 1;
        box-sizing: border-box;

        div.main-inner-wrapper {
            position: relative;
            height: calc(100% - variables.$ad-bottom-height);
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}
@media screen and (min-width:600px) {
    .mainWrapper {
        div.main {
            height: calc(100% - variables.$header-height);
            div.main-inner-wrapper {
                height: 100%;
            }
        }
    }
}