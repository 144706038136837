@charset "utf-8";

@use "styles/variables";

body {
    .component.CategoryListComponent {
        position: relative;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
        color: #ffffff;
        .category-list {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 10px 0 10px 60px;
            margin-bottom: 0;
            overflow: auto;
            border-right: 1px solid gray;
            .category-item {
                list-style: decimal;
                padding: 2px 0;
                font-size: 1.2rem;
            }
            .category-item:hover {
                cursor: pointer;
                background-color: rgba(200,100,255,0.2);
            }
        }
    }
}
@media screen and (min-width:600px) {
    body {
        .component.CategoryListComponent {
            .category-list {
                padding: 10px;
                list-style: none;
                .category-item {
                    list-style: none;
                    padding: 0 10px;
                    font-weight: normal;
                    font-size: 1.0rem;
                }    
                .category-item.selected {
                    background-color: variables.$bg-color-selected;
                    color: #ffffff;
                }
            }
        }
    }
}