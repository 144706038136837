@charset "utf-8";

@use "styles/variables";

.component.SelectCountryComponent {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    .list-area {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        overflow: auto;
        padding-bottom: 150px;
        .item-wrapper {
            width: 100%;
        }
        hr {
            margin: 0;
        }
    }
    .button-area {
        flex-grow: 0;
        flex-shrink: 0;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        left: 0;
        right: 0;
        margin: auto;
        column-gap: 10px;

        .button {
            width: variables.$bottom-2button-width;
            max-width: variables.$bottom-2button-max-width-sp;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
    }
}
@media screen and (min-width:600px) {
    .component.SelectCountryComponent {
        .list-area {
            padding-bottom: 0;
        }
        .button-area {
            position: sticky;
            bottom: 0;
            padding-bottom: 20px;
            padding-top: 20px;
            column-gap: 30px;
            .button {
                max-width: variables.$bottom-2button-max-width-pc;
            }    
        }
    }
}