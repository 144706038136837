@charset "utf-8";

@use "styles/variables";

div.ad-area.right {
    position: relative;
    display: none;
    width: variables.$ad-area-width;
    height: 100%;
    color: #000000;

    a {
        color: #0000ff;
    }

    div.dummy-area {
        width: variables.$ad-area-width;
        height: 100%;
    }

    div.ad-contents.right {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: variables.$ad-area-width;
        height: 100%;
        background-color: #eeffee;
        z-index: 15;
        overflow: hidden;

        .slider-wrapper {
            position: relative;
            top: 10vh;
    
            .slider-container {
                ul {
                    display: flex;
                    list-style: none;
                    div.item-container {
                        width: variables.$ad-area-width;
                        div.ad-item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: variables.$ad-area-width;
                        }
                    }
                }    
            }
        }
    }
}
@media screen and (min-width:1536px) {
    div.ad-area.right {
        display: block;
    }
}