@charset "utf-8";

@use "styles/variables";

.JoinGroupListItemUser {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 90%;
    margin: 5px auto;
    &:hover {
        cursor: pointer;
    }
    .card-image {
        width: auto;
        height: 100px;
        object-fit: cover;
    }
    .card-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        text-align: left;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        .info-wrapper {
            min-width: 0;
            .info-wrapper-1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                .flag-image {
                    object-fit: contain;
                    height: 20px;
                    margin-right: 5px;
                }
                .attribute {
                    color: #ffffff;
                    border-radius: 9999px;
                    font-size: 0.9rem;
                    padding: 1px 6px;
                }
                .attribute.male {
                    background-color: variables.$color-gender-male;
                }
                .attribute.female {
                    background-color: variables.$color-gender-female;
                }
                .attribute.other {
                    background-color: variables.$color-gender-other;
                }
            }
            .info-wrapper-2 {
                font-weight: bold;
                font-size: 1.2rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .info-wrapper-3 {
                display: flex;
                flex-direction: row;
                .age {
                    font-size: 0.8rem;
                }
                .gender {
                    font-size: 0.8rem;
                }
            }
            .info-wrapper-4 {
                font-size: 0.8rem;
            }
            .info-wrapper-5 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                font-size: 0.7rem;
                color: #aaaaaa;
                .card-icon {
                    font-size: 1.0rem;
                }
            }
        }
        .right-area {
            display: flex;
            flex-direction: row;
            align-items: center;
            .button-area {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                button {
                    width: auto;
                }
                .btn-decline {
                    margin-top: 10px;
                    background-color: variables.$color-danger;
                }
            }
            .img-arrow-forward {
                height: 30px;
                object-fit: cover;
            }
        }
    }
    .user-label {
        position: absolute;
        top: 0;
        left: 0;
        .online {
            background-color: variables.$color-online;
            color: #ffffff;
            border-radius: 9999px;
            padding: 2px 6px;
            margin: 2px;
            font-size: 0.7rem;
        }
        .follow {
            background-color: variables.$color-follow;
            color: #ffffff;
            border-radius: 9999px;
            padding: 2px 6px;
            margin: 2px;
            font-size: 0.7rem;
        }
    }
    .selected-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: variables.$bg-color-selected-layer;
        z-index: 10;
    }
}
@media screen and (min-width:600px) {
    .JoinGroupListItemUser {
        width: 70%;
        margin: 10px auto;
        .card-image {
            height: 120px;
        }    
        .card-content {
            padding: 10px;
            .info-wrapper-1 {
                .flag-image {
                    height: 30px;
                    margin-right: 10px;
                }
                .attribute {
                    padding: 2px 15px;
                }
            }
        }
        .online {
            padding: 4px 12px;
            margin: 4px;
        }
        .follow {
            padding: 4px 12px;
            margin: 4px;
        }
    }
}