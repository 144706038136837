@charset "utf-8";

@use "styles/variables";

.GroupListItem {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin: 5px auto;
    .item-content {
        padding: 5px;
        text-align: left;
        .status {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 5px;
            span {
                color: #ffffff;
                font-size: 0.8rem;
                padding: 2px 10px;
                border-radius: 9999px;
            }
            span.owner {
                background-color: variables.$bg-color-group-owner;
            }
            span.applying {
                background-color: variables.$bg-color-group-applying;
            }
            span.under-invited {
                background-color: variables.$bg-color-group-under-invited;
            }
            span.member {
                background-color: variables.$bg-color-group-member;
            }
            span.private {
                background-color: variables.$bg-color-group-private;
            }
        }
        .group-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .owner-name-area {
                display: flex;
                flex-direction: row;
                align-items: center;
                .owner-name-label {
                    font-size: 0.8rem;
                }
                .owner-name {
                    font-size: 1.0rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .owner-name.male {
                    color: variables.$color-gender-male;
                }
                .owner-name.female {
                    color: variables.$color-gender-female;
                }
                .owner-name.other {
                    color: variables.$color-gender-other;
                }
            }
            .group-name {
                font-weight: bold;
                font-size: 1.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .item-content:last-child {
        padding-bottom: 5px;
    }
}
.GroupListItem.selected {
    background-color: variables.$bg-color-selected;
}
@media screen and (min-width:600px) {
    .GroupListItem {
        margin: 10px auto;
        .item-content {
            padding: 10px;
        }
        .item-content:last-child {
            padding-bottom: 10px;
        }
    }
}