@charset "utf-8";

@use "styles/variables";

.pageWrapper.UserList {
    position: relative;
    height: 100%;
    margin: auto;
    padding-bottom: variables.$page-padding-bottom-sp;
    .list-container {
        position: relative;
        display: grid;
        justify-content: center;
        align-items: flex-start;
        grid-template-columns: repeat(auto-fill, variables.$user-list-item-width-sp); 
        grid-auto-rows: variables.$user-list-item-height-sp;
        column-gap: 10px;
        row-gap: 10px;
        padding: 10px 10px;

        .list-item-wrapper {
            position: relative;
            font-size: 1rem;
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -webkit-touch-callout: none;
        }
    }
    .scroll-to-top {
        position: fixed;
        bottom: 100px;
        right: 10px;
        z-index: 100;
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.UserList {
        padding-bottom: variables.$page-padding-bottom-pc;
        .list-container {
            grid-template-columns: repeat(auto-fill, variables.$user-list-item-width-pc); 
            grid-auto-rows: variables.$user-list-item-height-pc;
            row-gap: 20px;
            column-gap: 20px;

            .list-item-wrapper {
                height: 220px;
                &:hover {
                    cursor: pointer;
                }
            }
            .dummy {
                padding: 10px;
                width: 15%;
            }
        }
        .scroll-to-top {
            bottom: 32px;
        }    
    }
}