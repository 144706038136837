@charset "utf-8";

@use "styles/variables";

.component.SelectUserComponent {
    position: relative;
    width: 100%;
    height: calc(100% - variables.$navbar-height);
    margin: auto;

    .search-area {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .condition-area {
            flex-grow: 0;
            flex-shrink: 0;
            width: 90%;
            margin: auto;
            display: flex;
            flex-direction: row;
            padding-top: 20px;
            padding-bottom: 20px;
                
            .txt-user-name {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                padding: 0;
                border-radius: 4px;
                word-break: break-all;

                .MuiInputBase-root {
                    height: 100%;
                    input {
                        height: 100%;
                        padding: 0 10px;
                        background-color: #ffffff;
                        border-radius: 4px;
                    }
                }
            }

            .btn-search {
                background-color: #000000;
                font-size: variables.$fontsize-button;
                color: #ffffff;
                margin-left: 5px;
            }
        }
        .result-area {
            flex-grow: 1;
            flex-shrink: 1;
            position: relative;
            display: grid;
            justify-content: center;
            align-items: flex-start;
            grid-template-columns: repeat(auto-fill, variables.$user-list-item-width-sp); 
            grid-auto-rows: variables.$user-list-item-height-sp;
            column-gap: 10px;
            row-gap: 10px;
            padding: 10px 10px 80px 10px;
            overflow: auto;
    
            .item-wrapper {
                position: relative;
                font-size: 1rem;    
            }
            .item-wrapper.selected {
                background-color: variables.$bg-color-selected;
            }
            .scroll-trigger {
                position: fixed;
                bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 80px);
                right: 10;
                z-index: 100;
            }
        }
    }
    .button-area {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        left: 0;
        right: 0;
        margin: auto;
        column-gap: 10px;

        .button {
            width: variables.$bottom-2button-width;
            max-width: variables.$bottom-2button-max-width-sp;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
    }
}
@media screen and (min-width:600px) {
    .component.SelectUserComponent {
        width: 100%;
        height: 100%;
        .search-area {
            padding-bottom: 80px;
            .result-area {
                grid-template-columns: repeat(auto-fill, variables.$user-list-item-width-pc); 
                grid-auto-rows: variables.$user-list-item-height-pc;
                row-gap: 20px;
                column-gap: 20px;

                .scroll-trigger {
                    bottom: 32px;
                }
            }
        }
        .button-area {
            position: sticky;
            bottom: 20px;
            column-gap: 30px;
            .button {
                max-width: variables.$bottom-2button-max-width-pc;
            }    
        }
    }
}