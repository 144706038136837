@charset "utf-8";

@use "styles/variables";

.component.AttachedFileListForChatComponent {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 0;
    row-gap: 10px;
    margin: 10px 0;

    .imageWrapper {
        position: relative;
        width: variables.$attached-file-width-sp1;
        height: variables.$attached-file-height-sp;
        cursor: pointer;
        background-color: variables.$bg-color-attached-file;
        box-shadow: variables.$attached-file-shadow;
        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
    .videoThumbnailWrapper {
        position: relative;
        width: variables.$attached-file-width-sp1;
        height: variables.$attached-file-height-sp;
        cursor: pointer;
        background-color: variables.$bg-color-attached-file;
        box-shadow: variables.$attached-file-shadow;
        img.thumbnail {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
        img.play-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
    }
    .dummy {
        display: none;
    }
}
.component.AttachedFileListForChatComponent.partner {
    justify-content: flex-start;
}
@media screen and (min-width:600px) {
    .component.AttachedFileListForChatComponent {
        width: 100%;
        flex-direction: row;
        column-gap: 20px;
        row-gap: 20px;
        margin: 20px 0;
        .imageWrapper {
            width: variables.$attached-file-width-pc;
            height: variables.$attached-file-height-pc;
            img {
                
            }
        }
        .videoThumbnailWrapper {
            width: variables.$attached-file-width-pc;
            height: variables.$attached-file-height-pc;
            img.thumbnail {
                
            }
            img.play-icon {

            }
        }
        .dummy {
            display: block;
            width: variables.$attached-file-width-pc;
            height: 1px;
        }
    }
}