@charset "utf-8";

@use "styles/variables";

.GalleryListItem {
    position: relative;
    width: variables.$gallery-content-width-sp;
    height: variables.$gallery-content-width-sp;
    cursor: pointer;
    background-color: variables.$bg-color-attached-file;
    box-shadow: variables.$attached-file-shadow;
    img.image {
        object-fit: contain;
        width: 100%;
        height: 100%;
}
    .video-thumbnail-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        img.thumbnail {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        img.play {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 50px;
            object-fit: cover;
            margin: auto;
        }
    }
    .select-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: variables.$bg-color-selected-layer;    
    }
}
@media screen and (min-width:600px) {
    .GalleryListItem {
        width: variables.$attached-file-width-pc;
        height: variables.$attached-file-height-pc;
        .video-thumbnail-wrapper {
        }
    }
}