@charset "utf-8";

@use "styles/variables";

.component.UserChatComponent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #eeeeff;
    .chat-area-wrapper {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;
        overflow-x: hidden;
        .chat-display-area {
            width: 100%;
            height: 100%;
            // height: calc(100% - variables.$navbar-height);
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            background-color: #eeeeff;
            padding: 10px 10px 60px 10px;
        }
    }
    .chat-area-wrapper.hidden {
        visibility: hidden;
    }
    .chat-input-area {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        background-color: #ffffff;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .attached-file-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            row-gap: 2px;
            column-gap: 5px;
            margin-top: 5px;
        }
        .input-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .attach-file-icon {
                color: black;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
        .text-field {
            flex-grow: 1;
            flex-shrink: 1;
            background-color: #ffffff;
            height: 100%;
            border-radius: 4px;;
        }
        .button-area {
            padding: 0;
            padding-left: 5px;
            .send-button {
                object-fit: contain;
                width: 40px;
                height: 40px;
                color: variables.$color-primary;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .component.UserChatComponent {
        height: 100%;
        .chat-display-area {
            padding: 10px 20px 0 20px;
        }
        .chat-input-area {
            min-height: 80px;
            padding: 20px 10px;
        }
    }
}
@media screen and (min-width:1536px) {
}