@charset "utf-8";

@use "styles/variables";

.AlbumEdit {
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    .error-message-area {
        width: 100%;
        .error-message {
            margin-bottom: 0;
        }
    }
    .input-area {
        width: 90%;
        flex-grow: 1;
        flex-shrink: 1;
        margin: auto;

        .label {
            display: inline-block;
            text-align: left;
            flex-grow: 0;
            flex-shrink: 0;
            padding-right: 10px;
            width: 160px;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .radio-item {
            min-height: 25px;
        }
        .radio-item.select-group,
        .radio-item.select-user {
            display: flex;
            flex-direction: column;

            .select-area {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .group-name-list-area,
            .user-name-list-area {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                column-gap: 5px;
                row-gap: 5px;

                .group-name-wrapper,
                .user-name-wrapper {
                    color: #ffffff;
                    border-radius: 5px;
                    padding: 3px 10px;
                }
                .group-name-wrapper{
                    background-color: #aa55dd;
                }
                .user-name-wrapper {
                    background-color: #55aa55;
                }
            }
        }
        .select-group-button,
        .select-user-button {
            width: auto;
            margin: 0;
            padding: 1px 5px;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 0.9rem;
            color: #FFFFFF;
            background-color: #000000;
        }
        .select-group-button.disabled,
        .select-user-button.disabled {
            color: #000000;
            background-color: variables.$bg-color-button-disabled;
        }
        .select-group-button:hover,
        .select-user-button:hover {
            color: #000000;
            background-color: #FFFFFF;
        }

        /* アルバム名 */
        .album-name-area {
            display: flex;
            width: 100%;
            margin: 0;
            padding-top: 30px;
            flex-direction: column;
            align-items: flex-start;

            .album-name-value {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                height: variables.$text-field-height;
            }
        }
    
        /* 公開設定 */
        .album-public-setting-area {
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            align-items: flex-start;

            .radio-group {
                flex-grow: 1;
                flex-shrink: 1;

                .album-precautions {
                    margin-top: -10px;
                    font-size: 0.8rem;
                    color: #dadada;
                    margin-bottom: 0;
                    padding-left: 30px;    
                }
            }
        }
    
        /* テンプレート選択 */
        .album-template-select-area {
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            align-items: flex-start;

            .value-area {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;

                .img {
                    object-fit: contain;
                    height: 10vw;
                    min-height: 60px;
                    max-height: 120px;
                }
            }
        }
    }

    .btn-area {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: calc(variables.$ad-bottom-height + variables.$bottom-button-padding-bottom-sp);
        row-gap: 30px;
        margin-top: 50px;

        .button {
            width: variables.$bottom-button-width-sp;
            margin: auto;
            font-size: variables.$fontsize-button;
        }
    }
}
@media screen and (min-width:600px) {
    .AlbumEdit {
        width: 100%;
        overflow: visible;

        .input-area {
            flex-grow: 1;
            flex-shrink: 1;
            width: 90%;
            margin: auto;

            .radio-item {
                min-height: 40px;
            }
            .select-group-button,
            .select-user-button {
                padding: 3px 8px;
            }
    
            .album-name-area {
                padding-top: 40px;
                flex-direction: row;
                align-items: center;

                .album-name-value {
                    height: 45px;
                }
            }

            .album-public-setting-area {
                margin-top: 40px;
                flex-direction: row;
                align-items: center;
            }

            .album-template-select-area {
                margin-top: 40px;  
                flex-direction: row;
                align-items: center;
                
                .value-area {
                    margin-top: 20px;
                    align-items: center;

                    .img {
                        height: 80px;
                    }
                }
            }
        }

        .btn-area {
            padding-bottom: variables.$bottom-button-padding-bottom-pc;

            .button {
                width: variables.$bottom-button-width-pc;
            }
        }
    }
}