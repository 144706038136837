@charset "utf-8";

@use "styles/variables";

.pageWrapper.GroupList {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-bottom: variables.$page-padding-bottom-sp;
    .group-list-area {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        box-sizing: border-box;
        .item-wrapper {
            width: 90%;
            margin: auto;
            &:hover {
                cursor: pointer;
            }
        }
        .scroll-top {
            position: fixed;
            bottom: 100px;
            right: 10px;
            z-index: 100;
        }
    }
    .img-create-group {
        position: fixed;
        width: 50px;
        height: 50px;
        right: 10px;
        top: 50px;
        box-shadow: 1px 1px 5px 0px #333333;
        z-index: 50;
        border-radius: 9999px;
        background-color: #ffffff;;
        cursor: pointer;
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GroupList {
        .group-list-area {
            padding-top: 10px;
            .item-wrapper {
                width: 70%;
                margin: auto;
            }
            .scroll-top {
                bottom: 32px;
                right: 20px;
            }
        }
        .img-create-group {
            right: 20px;
            box-shadow: 5px 5px 10px 0px #333333;
            z-index: 50;
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.GroupList {
        .img-create-group {
            right: calc(30px + variables.$ad-area-width);
        }
    }
}