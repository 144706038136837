@charset "utf-8";

@use "styles/variables";

.component.SelectGroupComponent {
    position: relative;
    width: 100%;
    height: calc(100% - variables.$navbar-height);
    margin: auto;

    .search-area {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .condition-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            
            .txt-group-name {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                padding: 0;
                border-radius: 4px;
                word-break: break-all;

                .MuiInputBase-root {
                    height: 100%;
                    input {
                        height: 100%;
                        padding: 0 10px;
                        background-color: #ffffff;
                        border-radius: 4px;
                    }
                }
            }

            .btn-search {
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
                margin-left: 5px;
            }
        }
        .result-area {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: auto;
            padding-bottom: 80px;

            .no-group {
                text-align: center;
                color: red;
                font-size: 1.2rem;
            }
            .item-wrapper {
                width: 90%;
                margin: auto;
            }
            .item-wrapper.selected {
                background-color: variables.$bg-color-selected;
            }
            .scroll-trigger {
                position: fixed;
                bottom: calc(variables.$footer-height + 80px);
                right: 10;
                z-index: 100;
            }
        }
    }
    .button-area {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        left: 0;
        right: 0;
        margin: auto;
        column-gap: 10px;

        .button {
            width: variables.$bottom-2button-width;
            max-width: variables.$bottom-2button-max-width-sp;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
    }
}
@media screen and (min-width:600px) {
    .component.SelectGroupComponent {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .search-area {
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
            height: auto;
            padding-bottom: 0;
            overflow: auto;
            .result-area {
                width: 100%;
                margin: auto;
                padding-top: 10px;
                padding-bottom: 0;
                .scroll-trigger {
                    bottom: 32px;
                }    
            }
        }
        .button-area {
            position: relative;
            bottom: 0;
            padding: 20px 0;
            column-gap: 30px;
            .button {
                max-width: variables.$bottom-2button-max-width-pc;
            }    
        }
    }
}