@charset "utf-8";

@use "styles/variables";

.pageWrapper.GroupEdit {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;
    .contents-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding-bottom: 150px;
        .group-name-area {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .group-name-value {
                height: variables.$text-field-height;
                background-color: #ffffff;
            }
        }
        .public-setting-area {
        }
        .radio-group {
            display: flex;
            flex-direction: column;
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            text-align: center;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled;
                color: #000000;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GroupEdit {
        overflow: visible;
        h1.page-title {
            font-size: variables.$fontsize-page-title-pc;
            text-align: center;
            padding-top: variables.$page-title-padding-top;
            color: #ffffff;
        }
        .contents-wrapper {
            width: 70%;
            margin: auto;
            .radio-group {
                flex-direction: row;
                .radio-item {
                    min-width: 300px;
                }
            }    
            .button-area {
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
    }
}
