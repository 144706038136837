@charset "utf-8";

@use "styles/variables";

.pageWrapper.UserCommunication {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background: variables.$bg-color-app;
    display: flex;
    flex-direction: row;
    overflow: hidden !important;
    .talk-user-list-area-wrapper {
        position: relative;
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .divider {
        display: none;
    }
    .conversation-area-wrapper {
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.UserCommunication {
        .divider {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            background-color: #9955ff !important;
            background-color: transparent !important;
            // box-shadow: 2px 2px 4px 2px #333333;
            cursor: ew-resize;
        }
        .talk-user-list-area-wrapper {
            display: block;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
}