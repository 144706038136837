@charset "utf-8";

@use "styles/variables";

.pageWrapper.GroupDetail {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-bottom: variables.$page-padding-bottom-sp;
    .error-message {
        display: flex;
        margin: 0 0 10px 0;
    }
    .not-exist {
        width: 100%;
        color: variables.$color-danger;
        text-align: center;
        font-size: 1.1rem;
        margin-top: 30px;
    }
    .page-title {
        font-size: variables.$fontsize-page-title-pc;
        color: variables.$color-primary;
        text-align: center;
        padding-top: variables.$page-title-padding-top;
        color: #ffffff;
    }
    .contents-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        .icon-edit-wrapper {
            position: fixed;
            width: 50px;
            height: 50px;
            right: 10px;
            top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 9999px;
            background-color: #ffffff;
            box-shadow: 1px 1px 5px 0px #333333;
            z-index: 50;
            cursor: pointer;
            img {
                display: inline-block;
                object-fit: cover;
                color: #0000ff;
                height: 30px;
            }
        }

        .info-area {
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            .group-status-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
                .value {
                    color: #00FF00;
                }
            }
            .group-owner-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
            }
            .group-name-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
            }
            .group-member-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
            }
            .public-setting-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
            }
            .join-system-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-bottom: 10px;
            }
            .profile-area {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin-top: 20px;
            }
            .label {
                background-color: variables.$color-primary;
                color: #ffffff;
                padding: 5px 10px;
                width: 140px;
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                align-items: center;
            }
            .value {
                font-size: 1.1rem;
                padding-left: 5px;
                display: flex;
                align-items: center;
                word-break: break-all;
            }
        }
        .message-area {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 0;
            flex-shrink: 0;
            margin-top: 10px;
            .txt-message {
                background-color: #ffffff;
                padding: 5px 10px;
            }
        }
        .dummy {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
                max-width: 300px;
            }
            .button.two {
                flex-grow: 0;
                flex-shrink: 0;
                width: variables.$bottom-2button-width;
                max-width: variables.$bottom-2button-max-width-sp;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
            .button.red {
                background-color: variables.$color-danger;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled;
                color: #000000;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GroupDetail {
        padding-bottom: 0;
        .not-exist {
            font-size: 1.1rem;
            margin-top: 50px;
        }
        .contents-wrapper {
            width: 70%;
            margin: auto;
            margin-top: 30px;
            .info-area {
                .group-status-area {
                    margin-bottom: 20px;
                }
                .group-owner-area {
                    margin-bottom: 20px;
                }
                .group-name-area {
                    margin-bottom: 20px;
                }
                .group-member-area {
                    margin-bottom: 20px;
                }
                .public-setting-area {
                    margin-bottom: 20px;
                }
                .join-system-area {
                    margin-bottom: 20px;
                }
                .profile-area {
                    margin-top: 20px;
                }
                .label {
                    padding: 10px;
                    width: 200px;
                }
                .value {
                    padding-left: 10px;
                }
            }
            .message-area {
                margin-top: 20px;
            }
            .button-area {
                column-gap: 30px;
                bottom: variables.$bottom-button-padding-bottom-pc;
                .button {
                    width: variables.$bottom-button-width-pc;
                    max-width: none
                }
                .button.two {
                    max-width: variables.$bottom-2button-max-width-pc;
                }
            }
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.GroupDetail {
        .contents-wrapper {
            .icon-edit-wrapper {
                right: calc(10px + variables.$ad-area-width);
            }    
        }
    }
}
