@charset "utf-8";

@use "styles/variables";

.component.Profile {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - variables.$navbar-height);
    margin: auto;
    overflow: auto;
    .operation-area {
        position: relative;
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        flex-grow: 0;
        column-gap: 5px;
        margin: auto;
        margin-top: 20px;
        height: 30px;
        padding-bottom: 2px;
        
        .btn-color {
            height: 28px;
            width: 28px;
            background-color: fontColor;
            flex-shrink: 0;
            flex-grow: 0;
        }
        .btn-bigger {
            font-size: 1rem;
            flex-shrink: 0;
            flex-grow: 0;
            margin-left: 0;
            background-color: #000000;
            color: #ffffff;
        }
        .btn-smaller {
            font-size: 1rem;
            flex-shrink: 0;
            flex-grow: 0;
            margin-left: 0;
            background-color: #000000;
            color: #ffffff;
        }
        .btn-delete {
            font-size: 1rem;
            flex-shrink: 0;
            flex-grow: 0;
            object-fit: cover;
            height: 120%;
        }
    }
    .iframe-wrapper {
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        width: 90%;
        margin: auto;
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow:
                2px 2px 4px 0px #333333, 1px 1px 2px 0px #333333 inset;
            box-sizing: border-box;
            border-width: 0;
            background-color: #ffffff;
        }
    }
    .button-area {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: variables.$bottom-button-padding-bottom-sp;
        .button-ok {
            width: 140px;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
        .button-cancel {
            width: 140px;
            margin-left: 10px;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
    }
}
@media screen and (min-width:600px) {
    .component.Profile {
        width: 95%;
        .operation-area {
            height: 35px;
            .btn-color {
                width: 33px;
                height: 33px;
            }
            .btn-bigger {
                margin-left: 10px;
            }
            .btn-smaller {
                margin-left: 10px;
            }
        }
        .iframe-wrapper {
            min-height: 50vh;
        }
        .button-area {
            padding-bottom: 20px;
            padding-top: 20px;
            .button-ok {
                width: 150px;
            }
            .button-cancel {
                width: 150px;
                margin-left: 30px;
            }
        }
    }
}