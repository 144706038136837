@charset "utf-8";

@use "styles/variables";

.component.SelectTagComponent {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;

    .tag-area {
        position: relative;
        width: 90%;
        padding-top: 20px;
        padding-bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        margin: auto;

        .operation-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            
            .txt-custom-tag-name {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                padding: 0;
                border-radius: 4px;

                .MuiInputBase-root {
                    height: 100%;
                    input {
                        height: 100%;
                        padding: 0 10px;
                        background-color: #ffffff;
                        border-radius: 4px;
                    }
                }
            }

            .btn-add {
                flex-shrink: 0;
                width: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
                margin-left: 5px;
                padding: 3px 15px;
            }
        }
        .list-area {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .checkbox {
                padding-right: 0;
            }
        }
    }
    .button-area {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 20px);
        left: 0;
        right: 0;
        margin: auto;
        column-gap: 10px;
        z-index: 10;

        .button {
            width: variables.$bottom-2button-width;
            max-width: variables.$bottom-2button-max-width-sp;
            background-color: variables.$color-primary;
            font-size: variables.$fontsize-button;
            color: #ffffff;
        }
    }
}
@media screen and (min-width:600px) {
    .component.SelectTagComponent {
        padding-bottom: 0;
        width: 95%;
        overflow: visible;
        .tag-area {
            padding-bottom: 20px;
        }
        .operation-area {
            .btn-add {
            }
        }
        .button-area {
            position: sticky;
            bottom: 0;
            padding-bottom: 20px;
            padding-top: 20px;
            column-gap: 30px;
            .button {
                max-width: variables.$bottom-2button-max-width-pc;
            }    
        }
    }
}