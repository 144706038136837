@charset "utf-8";

@use "styles/variables";

.dialog.SendMessageDialog {
    width: 80vw;
    max-width: 800px;
    height: 100%;
    display: none;
    margin: auto;
    .dialog-title {
        position: relative;
        width: 100%;
        cursor: default;
        .icon-close {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            object-fit: contain;
            width: 30px;
            height: 30px;
            cursor: default;
        }
        .title {
            width: 100%;
            text-align: center;
        }
    }
    .dialog-content {
        position: relative;
        padding: 0;
        padding-top: 0;
        color: #000000;
        background-color: #eeeeee;
    }
}
@media screen and (min-width:600px) {
    .dialog.SendMessageDialog {
        display: block;
    }
}