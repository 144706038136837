@charset "utf-8";

@use "styles/variables";

.component.UserTalkComponent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: #000000;
    overflow: auto;

    .calling-area {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-close {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 10px;
            right: 10px;
            span {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 2px;
                display: inline-block;
                background-color: white;
            }
            span:nth-of-type(1) {
                transform: rotate(45deg);
            }
            span:nth-of-type(2) {
                transform: rotate(-45deg);
            }
        }
        .partner-thumbnail-area {
            position: relative;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img.partner-thumbnail {
                object-fit: cover;
                width: 30vw;
                height: 30vw;
                border-radius: 50%;
            }
            div.partner-name {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 1.2rem;
                color: variables.$color-gender-other;
                line-height: 1;
            }
            div.partner-name.male {
                color: variables.$color-gender-male;
            }
            div.partner-name.female {
                color: variables.$color-gender-female;
            }
        }
        .connecting-image-area {
            text-align: center;
            img.calling-image {
                width: 30px;
                height: 100px;
            }
        }
        div.user-icon-area {
            margin-top: 20px;
            .user-thumbnail {
                object-fit: cover;
                width: 20vw;
                height: 20vw;
                border-radius: 50%;
            }
        }
        div.user-name {
            padding-top: 10px;
            font-size: 1.2rem;
            color: variables.$color-gender-other;
            line-height: 1;
        }
        div.user-name.male {
            color: variables.$color-gender-male;
        }
        div.user-name.female {
            color: variables.$color-gender-female;
        }
        .button-area {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            img {
                width: 50px;
                height: 100%;
                object-fit: contain;
                background-color: red;
                color: white;
                fill: white;
                border-radius: 9999px;
                padding: 5px;
            }
        }
    }
    .talk-screen {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .operation-area {
            position: relative;
            width: 100%;
            height: 48px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            .talk-label {
                color: green;
                font-size: 1rem;
                padding-right: 5px;
            }
            .btn-start-casting, 
            .btn-stop-casting {
                font-size: 1.2rem;
                font-weight: bolder;
                background-color: yellowgreen;
                color: #ffffff;
                border-radius: 9999px;
                padding: 2px 10px;
            }
            .btn-finish-casting {
                position: absolute;
                height: 30px;
                top: 0;
                bottom: 0;
                right: 10px;
                margin: auto;
                background-color: red;
                color: #ffffff;
                border-radius: 9999px;
                padding: 5px 10px;
            }
        }
        .screen-area {
            height: calc(100% - 50px);
            .my-area {
                position: absolute;
                width: 100px;
                height: 100px;
                right: 0;
                bottom: 0;
                border: 5px solid #ffffff;
                border-radius: 20px;
                z-index: 10;
                background-color: #000000;
                touch-action: none;
                user-select: none;
                .pause-display {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    border-radius: 20px;
                }
                video {
                    width: 100%;
                    height: 100%;
                }
                img {
                    width: 100%;
                    max-height: 100%;
                    border-radius: 20px;
                    object-fit: contain;
                }
            }
            .partner-area {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .partner-area.video {
                overflow: auto;
                video {
                    width: auto;
                    height: calc(100% - 10px);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .partner-area.voice {
                img {
                    width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
                audio {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
}
@media screen and (min-width:600px) {
    .component.UserTalkComponent {
        padding-bottom: 0;
        .calling-area {
            .partner-thumbnail-area {
                img.partner-thumbnail {
                    width: 20vh;
                    height: 20vh;
                }
                div.partner-name {
                    font-size: 1.5rem;
                }
            }
            .connecting-image-area {
                img.calling-image {
                    width: 45px;
                    height: 150px;
                }
            }
            div.user-icon-area {
                margin-top: 20px;
                img.user-thumbnail {
                    width: 15vh;
                    height: 15vh;
                }
            }
            .button-area {
                position: absolute;
                height: 80px;
                bottom: variables.$bottom-button-padding-bottom-pc;
                img {
                    width: 80px;
                    padding: 10px;
                    cursor: pointer;
                }
            }
        }
        .talk-screen {
            .operation-area {

            }
            .screen-area {
                .my-area {
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
}
