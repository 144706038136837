@charset "utf-8";

@use "styles/variables";

$partner-name-area-height: 40px;

.component.UserCommunicationComponent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    background: #eeeeff;
    padding-bottom: 0;
    box-sizing: border-box;
    overflow-x: hidden;

    .partner-name-area {
        display: none;
    }
    .partner-name-area.male {
        color: variables.$color-gender-male;
    }
    .partner-name-area.female {
        color: variables.$color-gender-female;
    }
    .partner-name-area.other {
        color: variables.$color-gender-other;
    }
    
    .component-wrapper {
        position: absolute;
        left: 0;
        top: $partner-name-area-height;
        width: 100%;
        height: calc(100% - $partner-name-area-height);

        &.no-user {

        }

        &.chat {
            .anchor {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 20px;
                height: 80px;
                z-index: 100;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                margin: auto;
                span {
                    display: inline-block;
                    height: 30%;
                    width: 1px;
                    background-color: #333333;
                }
                span:nth-of-type(1) {
                }
                span:nth-of-type(2) {
                }
                &.open {
                    left: -20px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    background: linear-gradient(90deg, rgb(250, 250, 250) 0%, rgb(150, 150, 150) 100%);
                }
                &.close {
                    left: 0;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background: linear-gradient(90deg, rgb(150, 150, 150) 0%, rgb(250, 250, 250) 100%);
                }
            }
            &.visible {
                visibility: visible;
            }
            &.hidden {
                visibility: hidden;
                left: 100%;
            }
            &.right-hide {
                visibility: visible;
                left: 100%;
                z-index: 20;
                transition: left .5s ease;
                -webkit-transition: left .5s ease;
                -moz-transition: left .5s ease;
            }
            &.overlap {
                visibility: visible;
                left: 0;
                z-index: 20;
                transition: left .5s ease;
                -webkit-transition: left .5s ease;
                -moz-transition: left .5s ease;
            }
        }
        &.talk {
            &.visible {
                visibility: visible;
            }
            &.hidden {
                visibility: hidden;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .component.UserCommunicationComponent {
        .partner-name-area {
            position: relative;
            width: 100%;
            height: $partner-name-area-height;
            font-size:1.5rem;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;    
            background-color: #ddccdd;
            .button-area {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                .calling-button {
                    height: 90%;
                    object-fit: contain;
                    margin: auto;
                    margin-right: 20px;
                    cursor: pointer;
                    background-color: green;
                    border-radius: 9999px;
                    padding: 5px;
                }
            }
        }
    }
}
@media screen and (min-width:1536px) {
    .component.UserCommunicationComponent {
    }
}