@charset "utf-8";

@use "styles/variables";

.Footer {
    position: relative;
    display: block;
    width: 100%;
    z-index: 100;
    .dummy-for-sp {
        width: 100%;
        height: variables.$footer-height;
    }
    .footer-menu-list {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: variables.$footer-height;
        display: flex;
        flex-direction: row;
        background-color: #000000;
        padding: 10px 0;
        list-style: none;
        margin-bottom: 0;

        li.footer-menu-item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 0.5rem;

            img.img-footer-menu {
                object-fit: contain;
                height: 25px;
                margin-bottom: 2px;
            }

            .unread-badge-talk {
                position: absolute;
                padding: 2px;
                min-width: 15px;
                min-height: 15px;
                top: -8px;
                right: 8px;
                font-size: 0.7rem;
                background-color: #ff0000;
                color: #ffffff;
                border-radius: 9999px;
                justify-content: center;
                align-items: center;
            }

            .unread-badge-mail {
                position: absolute;
                padding: 2px;
                min-width: 15px;
                min-height: 15px;
                top: -8px;
                right: 8px;
                font-size: 0.7rem;
                background-color: #ff0000;
                color: #ffffff;
                border-radius: 9999px;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .Footer {
        display: none;
    }
}