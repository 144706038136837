@charset "utf-8";

@use "styles/variables";

.pageWrapper.ChildSafety {
    position: relative;
    width: 100%;
    height: 100%;
    color: #000000;
    background-color: #ffffff;
    margin: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;
    h1 {
        width: 100%;
        text-align: center;
        font-size: 1.3rem;   
        margin-top: 20px;  
    }
    .headline {
        font-size: 1.0rem;
        margin-top: 10px;
    }
    .counter-area {
        margin-top: 20px;
        .label {
            font-size: 1.1rem;
        }
        .contents {
            padding-left: 20px;
            a {
                color: blue;
            }
        }
    }
    .procedure-area {
        margin-top: 20px;
        .label {
            font-size: 1.1rem;
        }
        .contents {
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.ChildSafety {
        padding-left: 30px;
        padding-right: 30px;
        h1 {
            font-size: 1.5rem;   
        }
        .headline {
            font-size: 1.3rem;
            margin-top: 20px;
        }
    }
}
