@charset "utf-8";

@use "styles/variables";

.pageWrapper.TermsOfUse {
    position: relative;
    width: 100%;
    height: 100%;
    color: #000000;
    background-color: #ffffff;
    margin: auto;
    padding-left: 5%;
    padding-right: 5%;
    h1 {
        width: 100%;
        text-align: center;
        font-size: 1.3rem;   
        margin-top: 20px;  
    }
    ul {
        width: 100%;
        list-style-type: none;
        padding-left: 0;
        li {
            list-style-type: none;
            font-size: 1.0rem;
            margin-bottom: 20px;
            p {
                font-size: 1.1rem;
                margin-bottom: 0;
            }
        }
    }
    p#terms_date {
        text-align: right;
        margin-top: 30px;        
        padding-bottom: 50px;
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.TermsOfUse {
        padding-left: 30px;
        padding-right: 30px;
        h1 {
            font-size: 1.5rem;   
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.TermsOfUse {
    }
}