@charset "utf-8";

@use "styles/variables";

.pageWrapper.Login {
    position: relative;
    height: 100%;
    margin: auto;
    background: variables.$bg-color-app;

    .dummy-for-sp {
        display: block;
        width: 100%;
        height: variables.$navbar-height;
    }
    .error-message {
        display: flex;
    }
    form {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;
        margin: auto;
        padding-top: 30px;
        .google-oauth-area {
            margin-bottom: 20px;
        }
        .email-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 0;
            .email-label {
                width: 100%;
                text-align: left;
            }
            .email-value {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                height: variables.$text-field-height;
                padding: 0;
                border-radius: 4px;
                .MuiOutlinedInput-input {
                    height: 100%;
                    padding: 0 10px;
                    background-color: #ffffff;
                    border-radius: 5px;
                }
            }
        }
        .password-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            .password-label {
                width: 100%;
                text-align: left;
            }
            .password-value {
                flex-grow: 1;
                flex-shrink: 1;
                background-color: #ffffff;
                height: variables.$text-field-height;
                padding: 0;
                .MuiOutlinedInput-input {
                    height: 100%;
                    padding: 0 10px;
                    background-color: #ffffff;
                    border-radius: 5px;
                }
            }
        }
        .reset-password {
            text-align: right;
            padding-top: 10px;
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            text-align: center;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled;
                color: #000000;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.Login {
        padding-bottom: variables.$page-padding-bottom-pc;
        .dummy-for-sp {
            display: none;
        }
        .page-title {
            font-size: variables.$fontsize-page-title-pc;
            color: #ffffff;
            text-align: center;
            padding-top: variables.$page-title-padding-top;
        }
        form {
            width: 70%;
            padding-top: 10px;
            .password-area {
                margin-top: 50px;
            }
            .button-area {
                bottom: variables.$bottom-button-padding-bottom-pc;
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
    }
}