@charset "utf-8";

@use "styles/variables";

.pageWrapper.GroupChat {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #ffffff;

    div.chat-area {
        position: relative;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        background-color: #eeeeff;
        box-sizing: border-box;
        padding:10px 10px 10px 10px;
        overflow: auto;
        color: #000000;
        .ChatUserList {
            position: fixed;
            top: calc(variables.$header-height + variables.$navbar-height);
            bottom: calc(variables.$footer-height + variables.$ad-bottom-height + 50px);
            right: 0;
            width: 35%;
            min-width: 150px;
            max-width: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 10px;
        }
        a {
            color: #0000ff;
        }
    }
    div.chat-input-area {
        // position: fixed;
        // bottom: calc(variables.$footer-height + variables.$ad-bottom-height);
        // left: 0;
        flex-shrink: 0;
        flex-grow: 0;
        position: relative;
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;
        background-color: #eeeeee;
        // padding: 0 10px 10px 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        div.input-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GroupChat {
        padding-bottom: 0;
        div.chat-area {
            padding: 10px 20px 10px 20px;
            .ChatUserList {
                bottom: 70px;
            }
        }
        div.chat-input-area {
            bottom: 0;
            padding: 0 10px 20px 10px;
        }
    }
}
@media screen and (min-width:1536px) {
    .pageWrapper.GroupChat {
        div.chat-area {
            .ChatUserList {
                right: variables.$ad-area-width;
            }
        }
        div.chat-input-area {
        }
    }
}