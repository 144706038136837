@charset "utf-8";

@use "styles/variables";

.component.ResponseListComponent {
    position: relative;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
        color: #0000ff !important;
    }
    .dummy-for-sp {
        display: none;
    }

    .contents-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px 2% 0 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: auto;
        text-align: left;
        overflow: auto;
        padding-bottom: variables.$page-padding-bottom-sp;
        .thread-title {
            font-weight: bold;
            font-size: variables.$fontsize-page-title-sp;
            line-height: 1;
            word-break: break-all;
            padding-bottom: 20px;
            color: #ffffff;
        }
        .input-area {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .body-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin-top: 20px;
                padding-bottom: 20px;
                .body-label {
                    width: 100%;
                    text-align: left;
                    font-size: 1.2rem;
                }
                .body-value {
                    flex-grow: 1;
                    flex-shrink: 1;
                    background-color: #ffffff;
                    font-size: 1.0rem;
                }
            }
            .button-area {
                width: 100%;
                text-align: center;
                .button {
                    width: variables.$bottom-button-width-sp;
                    margin: auto;
                    background-color: variables.$color-primary;
                    font-size: variables.$fontsize-button;
                    color: #ffffff;
                }
                .button.disabled {
                    background-color: variables.$bg-color-button-disabled;
                    color: #000000;
                }
            }
        }
    }

}
@media screen and (min-width:600px) {
    .component.ResponseListComponent {
        padding-bottom: 0;
        .contents-wrapper {
            padding: 20px 5% 0 5%;
            .thread-title {
                font-size: variables.$fontsize-page-title-pc;
                padding-bottom: 30px;
            }
            .input-area {
                padding-bottom: variables.$bottom-button-padding-bottom-pc;
                .body-area {
                    margin-top: 50px;
                    padding-bottom: 30px;
                }
                .button-area {
                    .button {
                        width: variables.$bottom-button-width-pc;
                    }
                }    
            }
        }
    }
}