@charset "utf-8";

@use "styles/variables";

.component.TalkUserComponent {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        .card-image {
            width: 70px;
            height: 70px;
            object-fit: cover;
        }
        .card-content {
            padding: 0;
            .info-wrapper-1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 5px;
                .flag-image {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                }
                .attribute {
                    height: 23px;
                    font-size: 0.8rem;
                    color: #ffffff;
                    border-radius: 9999px;
                    padding: 2px 5px;
                }
                .attribute.male {
                    background-color: variables.$color-gender-male;
                }
                .attribute.female {
                    background-color: variables.$color-gender-female;
                }
                .attribute.other {
                    background-color: variables.$color-gender-other;
                }
            } 
        }
    }
}
@media screen and (min-width:600px) {
    .component.TalkUserComponent {
    }
}