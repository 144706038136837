@charset "utf-8";

@use "styles/variables";

html {
    .pageWrapper.ManageGroupList {
        position: relative;
        width: 100%;
        height: 100%;
        .no-joined-group-page {
            height: 100%;
            width: 85%;
            margin: auto;
            padding-top: 50px;
            color: variables.$color-primary;
            font-weight: bold;
            font-size: 1.5rem;
            text-align: center;
        }
        .contents-wrapper {
            padding-bottom: 20px;
            .list-wrapper {
                padding-bottom: calc(variables.$ad-bottom-height + 20px);
                h2 {
                    text-align: center;
                    font-size: 1.1rem;
                    color: #ffffff;
                    padding: 5px 0;
                    margin-bottom: 0;
                }
                h2.under-applied {
                    background-image: url("../../../../public/images/section_header/section_header_joingroup_1.png");
                    background-repeat: repeat-x;
                    background-size: contain;
                }
                h2.under-invited {
                    background-image: url("../../../../public/images/section_header/section_header_joingroup_1.png");
                    background-repeat: repeat-x;
                    background-size: contain;
                }
                h2.applying {
                    background-image: url("../../../../public/images/section_header/section_header_joingroup_2.png");
                    background-repeat: repeat-x;
                    background-size: contain;
                }
                h2.inviting {
                    background-image: url("../../../../public/images/section_header/section_header_joingroup_2.png");
                    background-repeat: repeat-x;
                    background-size: contain;
                }
                h2.joined {
                    background-image: url("../../../../public/images/section_header/section_header_joingroup_3.png");
                    background-repeat: repeat-x;
                    background-size: contain;
                }
                .group-list-wrapper {
                    width: 100%;
                    .sub-header-group-list {
                        width: 100%;
                        background-image: url("../../../../public/images/section_header/section_sub_header_joingroup.png");
                        background-repeat: repeat-x;
                        background-size: contain;    
                    }
                    .right-area {
                        .button-area {
                            button {
                                width: auto;
                                font-size: 0.9rem;
                                padding: 3px 6px;
                            }
                        }
                    }
                }
                .user-list-wrapper {
                    width: 100%;
                    .sub-header-user-list {
                        width: 100%;
                        background-image: url("../../../../public/images/section_header/section_sub_header_joingroup.png");
                        background-repeat: repeat-x;
                        background-size: contain;    
                    }
                    .right-area {
                        .button-area {
                            button {
                                width: auto;
                                font-size: 0.9rem;
                                padding: 3px 6px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dialog {
        .comment {
            padding: 5px;
        }
    }
}
@media screen and (min-width:600px) {
    html {
        .pageWrapper.ManageGroupList {
            .contents-wrapper {
                .list-wrapper {
                    padding-bottom: 40px;
                    .group-list-wrapper {
                        .right-area {
                            .button-area {
                                button {
                                    font-size: 1.0rem;
                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                    .user-list-wrapper {
                        .right-area {
                            .button-area {
                                button {
                                    font-size: 1.0rem;
                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}