@charset "utf-8";

@use "styles/variables";

html {
    .pageWrapper.GroupMemberList {
        position: relative;
        width: 100%;
        height: 100%;
            margin: auto;
        padding-bottom: variables.$page-padding-bottom-sp;
        .group-name {
            text-align: center;
            font-weight: bold;
            font-size: 1.4rem;
            margin: 10px auto 0 auto;
        }
        .list-container {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: auto;
            padding: 10px 2% 0 2%;
            row-gap: 10px;
            column-gap: 10px;
            .list-item-wrapper {
                position: relative;
                width: 30%;
                font-size: 1rem;
            }
            .dummy {
                padding: 5px;
                width: 30%;
            }
        }
        .scroll-to-top {
            position: fixed;
            bottom: 100px;
            right: 10px;
            z-index: 100;
        }
    }
    .message-label {
        margin-top: 10px;
    }
    .purge-message {
        padding: 5px;
    }
}
@media screen and (min-width:600px) {
    html {
        .pageWrapper.GroupMemberList {
            padding-bottom: variables.$page-padding-bottom-pc;
            .page-title {
                width: 100%;
                text-align: center;
                font-size: variables.$fontsize-page-title-pc;
                padding-top: 20px;
            }
            .list-container {
                padding: 30px 0 50px 0;
                row-gap: 20px;
                column-gap: 20px;
                .list-item-wrapper {
                    width: 15%;
                    min-width: 200px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .dummy {
                    padding: 10px;
                    width: 15%;
                }
            }
            .scroll-to-top {
                bottom: 32px;
            }    
        }
    }
}