@charset "utf-8";

@use "styles/variables";

* {
    margin: 0;
    padding: 0;
}
ul {
    margin: 0;
    padding: 0;
}
html {
    .App {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        color: #ffffff;
        .backdrop {
            color: #ffffff;
            .backdrop-content-wrapper {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .backdrop-content {
                    font-size: 1.2rem;
                }
            }
        }
        .router-wrapper {
            height: 100%;
            .pageWrapper {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
        .divider {
            background-color: #000000;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .pageWrapper {
            overflow: auto;
            height: 100%;
        }
    }
    .dialog {
        .dialog-title {
            min-width: 80vw;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 1.0) 30%);;
            color: #ffffff;
            font-size: 1.0rem;
            padding: 5px 0;
            text-align: center;
        }
        .dialog-title.red {
            background-color: variables.$color-danger;
        }
        .dialog-content {
            min-height: 100px;
            padding: 5px;
            padding-top: 5px;
            color: #000000;
            background-color: #eeeeee;
        }
        .dialog-content2 {
            min-height: 70px;
            padding: 5px;
        }
    }
    .dialog.fullscreen {
        position: fixed;
        top: variables.$header-height;
        left: 0;
        width: 100%;
        height: calc(100% - variables.$header-height);
        .MuiModal-backdrop {
            top: variables.$header-height;
        }
    }
    .app-button {
        width: 70%;
        font-size: 1.0rem;
    }
    .app-button, .dialog-button {
        background-color: #000000 !important;
        color: #ffffff !important;
    }
    .app-button:hover,
    .dialog-button:hover {
        background-color: #ffffff !important;
        color: #000000 !important;
        border: 1px solid #000000 !important;
    }
    a {
        color: variables.$a-color;
    }
}
@media screen and (min-width:600px) {
    html {
        .App {
            .divider {
                margin-top: 30px;
                margin-bottom: 10px;
            }    
        }
        .dialog {
            .dialog-title {
                min-width: 400px;
            }
            .dialog-content {
                padding: 10px;
                padding-top: 10px;
            }
            .dialog-content2 {
                min-height: 100px;
                padding: 10px;
            }
        }    
        .app-button {
            width: 350px;
            font-size: 1.2rem;
        }
    }
}