@charset "utf-8";

@use "styles/variables";

.pageWrapper.GroupInvite {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: auto;
    .contents-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
        .no-group {
            text-align: center;
            color: variables.$color-primary;
            font-size: 1.3rem;
        }
        .page-title {
            text-align: center;
            font-size: variables.$fontsize-page-title-sp;
            color: #ffffff;
        }
        .list-wrapper {
            padding-top: 10px;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            padding-bottom: 20px;
            .item-wrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                .group-name {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
                .group-name.selected {
                    color: rgb(0, 255, 0);
                }
                .join {
                    color: #ffffff;
                    background-color: variables.$color-purple;
                    padding: 5px 10px;
                    white-space: nowrap;
                }
                .inviting {
                    color: #ffffff;
                    background-color: variables.$color-purple;
                    padding: 5px 10px;
                    white-space: nowrap;
                }
                .under-application {
                    color: #ffffff;
                    background-color: variables.$color-dark-yellow;
                    padding: 5px 10px;
                    white-space: nowrap;
                }
                .switch {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }
        .message-area {
            padding-bottom: 100px;
        }
        .button-area {
            position: fixed;
            width: 100%;
            bottom: calc(variables.$bottom-button-distance + variables.$ad-bottom-height);
            left: 0;
            text-align: center;
            .button {
                width: variables.$bottom-button-width-sp;
                margin: auto;
                background-color: variables.$color-primary;
                font-size: variables.$fontsize-button;
                color: #ffffff;
            }
            .button.red {
                background-color: variables.$color-danger;
            }
            .button.disabled {
                background-color: variables.$bg-color-button-disabled;
                color: #000000;
            }
        }
    }
}
@media screen and (min-width:600px) {
    .pageWrapper.GroupInvite {
        overflow: visible;
        .contents-wrapper {
            width: 70%;
            margin: auto;
            padding-bottom: 100px;
            h1.page-title {
                font-size: variables.$fontsize-page-title-pc;
                color: #ffffff;
                text-align: center;
                padding-top: variables.$page-title-padding-top;
            }
            .message-area {
                padding-bottom: 150px;
            }
            .button-area {
                bottom: variables.$bottom-button-padding-bottom-pc;
                .button {
                    width: variables.$bottom-button-width-pc;
                }
            }
        }
    }
}