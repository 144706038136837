@charset "utf-8";

@use "styles/variables";

.CountryListItem {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 5px 0;
    .check-area {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img.img-check {
            object-fit: contain;
            color: #5555cc;
            width: 40px;
        }
    }
    .flag-area {
        padding-left: 20px;
        img {
            object-fit: contain;
            width: 25px;
        }
    }
    .name-area {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 1.0rem;
    }
}
.CountryListItem.selected {
    background-color: variables.$bg-color-selected;
}
@media screen and (min-width:600px) {
    .CountryListItem {
        padding: 10px 0;
        .check-area {
            img.img-check {
            }
        }
        .flag-area {
            padding-left: 40px;
            img {
                width: 40px;
            }
        }
        .name-area {
            font-size: 1.3rem;
        }
    }
}